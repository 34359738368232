<lib-panel-menu
  #panelmenu
  styleClass="screenMenu"
  [multiple]="false"
  [(model)]="screenList"
  [style]="{ width: '100%' }"
  #screenMenu
></lib-panel-menu>
<!-- <br />
<lib-panel-menu
  class="associatedMenu"
  *ngIf="associatedScreens[0].items.length > 0"
  [multiple]="false"
  [(model)]="associatedScreens"
  [style]="{ width: '100%' }"
></lib-panel-menu> -->


