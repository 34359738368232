<div class="p-grid relative" role="navigation" aria-label="main navigation">
  <div class="p-col-12 no-pad">
    <div class="p-grid primaryMenu-container">
      <div class="p-col-fixed " style="width:69rem;">
        <lib-menubar
          styleClass="primaryMenu"
          [model]="primaryMenu"
        ></lib-menubar>
      </div>
      <div class="p-col relative message-container"></div>
    </div>


  </div>
  <div class="p-col-12 no-pad relative">
    <lib-menubar styleClass="secondaryMenu" [model]="subMenu"></lib-menubar>
    <lib-split-button
      class="processBtn"
      label="Actions"
      icon="fa fa-plus"
      [model]="actionListItems"
      [isSingleButton]="true"
      styleClass="ui-button-info ActionButton  pull-right"
    ></lib-split-button>
    <lib-link
      (onClick)="showHelp()"
      class="helpIcon"
      styleClass="nv-help2"
      title="Screen Help"
    ></lib-link>
  </div>
</div>


