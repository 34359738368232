import { Component, TemplateRef, ViewChild } from "@angular/core";
import { ConfirmationType } from "@mynjhelps/common";
import { ButtonComponent } from "@mynjhelps/custom-component";
import { MessageService } from "@mynjhelps/message";
import { ViewBaseComponent } from "@mynjhelps/uibase";

@Component({
  // tslint:disable-next-line:component-selector
  selector: "lib-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent extends ViewBaseComponent {
  @ViewChild("yesBtn") yesButton: ButtonComponent;
  showDialog: boolean = false;
  private yesCallBack = null;
  private noCallBack = null;
  private closeCallback = null;
  showNo: boolean = true;
  yesButtonLabel: string = "Yes";
  noButtonLabel: string = "No";
  header: string = "Confirmation Box";
  message: string = "Do you want to continue?";
  messageTemplate: TemplateRef<any>;
  private isBtnClicked: boolean = false;

  constructor(private messageService: MessageService) {
    super();
    messageService.showConfirmation$.subscribe(messageInfo => {
      if (messageInfo.type && messageInfo.type === ConfirmationType.okCancel) {
        this.yesButtonLabel = "Ok";
        this.noButtonLabel = "Cancel";
      } else {
        this.yesButtonLabel = "Yes";
        this.noButtonLabel = "No";
      }
      if (messageInfo.messageToShow instanceof TemplateRef) {
        this.messageTemplate = messageInfo.messageToShow;
      } else {
        this.messageTemplate = undefined;
        this.message = messageInfo.messageToShow || this.message;
      }

      this.header = messageInfo.header || this.header;
      this.yesCallBack = messageInfo.yesCallback;
      this.noCallBack = messageInfo.noCallback;
      this.closeCallback = messageInfo.closeCallback;
      if (this.noCallBack) {
        this.showNo = true;
      } else {
        this.showNo = false;
      }
      this.isBtnClicked = false;
      this.showDialog = true;
    });
  }
  /**
   * Triggers when "Yes" clicks
   * @returns void
   */
  yesClicked(): void {
    this.isBtnClicked = true;
    this.yesCallBack();
    this.showDialog = false;
  }
  /**
   * Triggres when "NO" click
   * @returns void
   */
  noClicked(): void {
    this.isBtnClicked = true;
    this.noCallBack();
    this.showDialog = false;
  }
  /**
   * Triggers when dialog shows.
   * @returns void
   */
  onDialogShow(): void {
    setTimeout(() => {
      this.yesButton.focus();
    }, 0);
  }
  /**
   * Triggers when dialog hides
   * @returns void
   */
  onDialogHide(): void {
    if (!this.noCallBack && !this.isBtnClicked) {
      this.yesCallBack();
    }
    if (this.closeCallback && !this.isBtnClicked) {
      this.closeCallback();
    }
  }
  /**
   * Initialize the View and Variables
   * @returns void
   */
  onInitialize(): void {}
  /**
   * Trigger when View is Ready
   * @returns void
   */
  onViewReady(): void {}
  /**
   * Dispose Resources like Subscribers.
   * @returns void
   */
  onDestroy(): void {}
}
