import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";
@Pipe({ name: "feinPipe" })
export class FeinPipe implements PipeTransform {
  transform(feinNumb: any): string {
    if (!feinNumb || feinNumb === MyNjHelpsConstants.NULL_INT) {
      return "";
    }

    let feinValue = feinNumb.toString();
    if (feinValue.length < MyNjHelpsConstants.NUMBER_9) {
      for (
        let index = feinValue.length;
        index < MyNjHelpsConstants.NUMBER_9;
        index++
      ) {
        feinValue = "0" + feinValue;
      }
    }

    if (feinValue.length > MyNjHelpsConstants.NUMBER_0) {
      const part1 = feinValue.slice(
        MyNjHelpsConstants.NUMBER_0,
        MyNjHelpsConstants.NUMBER_2
      );
      const part2 = feinValue.slice(MyNjHelpsConstants.NUMBER_2, feinValue.length);
      const formattedFein = part1 + "-" + part2;

      return formattedFein;
    }

    return feinNumb;
  }
}
