export const environment = {
  production: true,
  applicationName: "MyNjHelpsCustomer",
  appSettingsId: null,
  appVersion: 1,
  appLastBuild: null,
  baseUrl: "MyNjHelpsCustomer",
  defaultUrl: "WRKL",
  environment: "P", // D- Development, T-Testng ,S-Training , P-Production
  isProduction: true,
  checkAccess: true,
  allowedIdleTimeMins: 15, // Mins
  downloadLimit: 100,
  defaultRefmValue: {
    label: "-SELECT-",
    value: ""
  },
  logConfig: {
    logBaseUrl: "https://mynjhelps.gov/api/",
    logApiMethod: "childsupport-api/api/anonymous/log/error"
  },
  apiConfig: {
    apiEndPoint: "https://mynjhelps.gov/api/",
    serviceRetryCount: 3,
    serviceRetryDelay: 500, // ms 
    service2Cache: [],
    sessionTimeOut: 15, // Mins
    sessionWarningTime: 1
  },
  authConfig: {
    apiUrl: "https://ibmservices.taskmanagement.center/cain/",
    authUrl: "http://192.168.111.16:9080/server-proxy/",
    authMethod: "oauth/token",
    accessTokenContainer: "access_token",
    refreshTokenContainer: "refresh_token",
    sessionTimeOut: 14 // Mins
  },
  startTime: "6:00 AM",
  endTime: "6:00 PM",
  jsonEncode: true ,
  siteKey: "6LeFAagpAAAAAKflwBVxvT03EW_cEckDOsUyDqpF"
};


