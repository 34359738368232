import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output
} from "@angular/core";

import { FormGroup } from "@angular/forms";
import {
  EventArgs,
  ConfirmationType,
  ScreenType,
  MessageModel,
  MyNjHelpsConstants
} from "@mynjhelps/common";
import {
  ScreenComponent,
  ScreenFormComponent,
  ViewBaseComponent
} from "@mynjhelps/uibase";
import { IScreenInfo } from "@mynjhelps/uibase";
import { ButtonConfig, ButtonType } from "./page-container.model";
import { ScreenService } from "@shared/services/screen.service";
@Component({
  selector: "app-page-container",
  templateUrl: "./page-container.component.html",
  styleUrls: ["./page-container.component.scss"]
})
export class PageContainerComponent extends ScreenFormComponent {
  private accessMessageCode: string = "E0439";
  screenInfo: IScreenInfo;
  @Input() isLongForm: boolean = false;

  @Input() header: string = "";
  @Input() headerTextLayoutClass: string = "p-col-8";
  @Input() headerButtonLayoutClass: string = "p-col-4";
  @Input() buttonConfig: ButtonConfig[] = [];
  @Input() page: any;
  @Input() forceClear: false;
  @Input() forceRefresh: false;
  @Output() onAddClick: EventEmitter<Event> = new EventEmitter();
  @Output() onDeleteClick: EventEmitter<Event> = new EventEmitter();
  @Output() onRefreshClick: EventEmitter<Event> = new EventEmitter();
  @Output() onSaveClick: EventEmitter<Event> = new EventEmitter();
  @Output() onCloseClick: EventEmitter<Event> = new EventEmitter();
  @Output() onClearClick: EventEmitter<Event> = new EventEmitter();

  private buttonInfo = {
    1: {
      label: "Add",
      icon: "fa fa-plus"
    },
    2: {
      label: "Delete",
      icon: "fa fa-close"
    },
    3: {
      label: "Refresh",
      icon: "fa fa-refresh"
    },
    4: {
      label: "Save",
      icon: "fa fa-cloud-upload"
    },
    5: {
      icon: "fa fa-close"
    },
    6: {
      label: "Clear",
      icon: "fa fa-eraser"
    }
  };

  constructor(private screenService: ScreenService) {
    super();
  }

  checkAccess(type) {
    return true;
    const currentPageInfo = JSON.parse(this.storageService.getItem("pageInfo"));

    const accessInfo = JSON.parse(this.storageService.getItem("access"));
    if (currentPageInfo.screenFunction === accessInfo.screenFn) {
      const accessData = accessInfo.accessData;
      if (accessData[type]) {
        return true;
      }
    }

    const errorMessages = JSON.parse(this.storageService.getItem("EMSG"));
    const messgae = new MessageModel();
    messgae.Code = "F";
    messgae.Description = errorMessages[this.accessMessageCode];
    this.messageService.ShowErrorDetails(messgae);

    return false;
  }

  // {"screenFn":"DREFDATT","accessData":{"accessInd":true,"deleteInd":true,"insertInd":true,"selectInd":true,"updateInd":true}}
  onClick(event: Event, buttonInfo: ButtonConfig, isBottom?) {
    if (!event.isTrusted && isBottom) {
      return;
    }
    let formHasValue: boolean;
    let hasFoolsTouch: boolean;
    switch (buttonInfo.buttonType) {
      case ButtonType.add:
        // this.screenService
        //   .checkScreen()
        //   .then(() => {
        if (this.checkAccess("insertInd")) {
          this.onAddClick.emit(event);
        }
        // })
        // .catch(() => {});

        break;
      case ButtonType.delete:
        if (this.checkAccess("deleteInd")) {
          this.messageService.isKeyIdentiferSet().then(() => {
            this.onDeleteClick.emit(event);
          });
        }
        break;
      case ButtonType.clear:
        formHasValue = this.page ? this.page.formHasValue() : false;
        hasFoolsTouch = this.page ? this.page.hasFoolsTouch() : false;
        let isScreenGridBusy = false;

        if (
          this.messageService.activeGrid &&
          this.messageService.activeGrid.screenName ===
            this.messageService.activeScreen.screenInfo.screenName
        ) {
          isScreenGridBusy = this.messageService.activeGrid.isBusy;
        }

        if (
          (formHasValue && !hasFoolsTouch) ||
          this.forceClear ||
          isScreenGridBusy
        ) {
          this.triggerConfirmEvent(
            event,
            this.onClearClick,
            this.doGetErrorMessage("W0211")
          );
        } else {
          // if (this.page.rootFormGroup.status === "INVALID")
          //  Case - Application Needs Force Clear i.e Act like New Button
          this.onClearClick.emit(event);
        }
        this.messageService.ShowErrorDetails([]);
        break;
      case ButtonType.save:
        const pageScreenInfo: IScreenInfo = JSON.parse(
          this.storageService.getItem("pageInfo")
        );
        if (pageScreenInfo.screenType === ScreenType.add) {
          if (this.checkAccess("insertInd")) {
            this.onSaveClick.emit(event);
          }
        } else if (pageScreenInfo.screenType === ScreenType.edit) {
          if (this.checkAccess("updateInd")) {
            this.onSaveClick.emit(event);
          }
        } else if (pageScreenInfo.screenType === ScreenType.delete) {
          if (this.checkAccess("deleteInd")) {
            this.onSaveClick.emit(event);
          }
        } else {
          this.onSaveClick.emit(event);
        }
        break;
      case ButtonType.close:
        {
          let isGridBusy = false;

          if (
            this.messageService.activeGrid &&
            this.messageService.activeGrid.screenName ===
              this.messageService.activeScreen.screenInfo.screenName
          ) {
            isGridBusy = this.messageService.activeGrid.isBusy;
          }
          if (isGridBusy) {
            const msgHeader = MyNjHelpsConstants.UNSAVED_DATA_HEADER;
            const msgDesc = MyNjHelpsConstants.UNSAVED_DATA_ERROR;
          }

          if (
            (!this.page || (this.page && !this.page.rootFormGroup)) &&
            isGridBusy
          ) {
            this.messageService.showConfirmation(
              MyNjHelpsConstants.UNSAVED_DATA_HEADER,
              MyNjHelpsConstants.UNSAVED_DATA_ERROR,
              () => {
                this.onCloseClick.emit(event);
              },
              () => {}
            );
          } else {
            this.onCloseClick.emit(event);
          }
        }
        break;
      case ButtonType.refresh:
        if (!this.page) {
          this.onRefreshClick.emit(event);

          return;
        }
        formHasValue =
          this.page && this.page.rootFormGroup
            ? this.page.formHasValue()
            : false;
        hasFoolsTouch =
          this.page && this.page.rootFormGroup
            ? this.page.hasFoolsTouch()
            : false;
        let isGridBusy = false;
        if (
          this.messageService.activeGrid &&
          this.messageService.activeGrid.screenName ===
            this.messageService.activeScreen.screenInfo.screenName
        ) {
          isGridBusy = this.messageService.activeGrid.isBusy;
        }

        if (
          (formHasValue && !hasFoolsTouch) ||
          this.forceRefresh ||
          isGridBusy
        ) {
          this.triggerConfirmEvent(
            event,
            this.onRefreshClick,
            this.doGetErrorMessage("W0212")
          );
        } else if (
          this.page.rootFormGroup &&
          this.page.rootFormGroup.status === "INVALID"
        ) {
          this.onRefreshClick.emit(event);
        }
        this.messageService.ShowErrorDetails([]);
        break;
      default:
        if (buttonInfo.customButton.buttonType) {
          if (buttonInfo.customButton.buttonType === ButtonType.edit) {
            // this.screenService
            //   .checkScreen()
            //   .then(() => {
            if (this.checkAccess("updateInd")) {
              buttonInfo.customButton.callback(event);
            }
            // })
            // .catch(() => {});
          } else if (buttonInfo.customButton.buttonType === ButtonType.add) {
            if (this.checkAccess("insertInd")) {
              buttonInfo.customButton.callback(event);
            }
          } else if (buttonInfo.customButton.buttonType === ButtonType.delete) {
            if (this.checkAccess("deleteInd")) {
              buttonInfo.customButton.callback(event);
            }
          } else {
            buttonInfo.customButton.callback(event);
          }
        } else {
          buttonInfo.customButton.callback(event);
        }

        break;
    }
  }

  private triggerConfirmEvent(
    event: Event,
    customEvent: EventEmitter<{}>,
    message: string
  ) {
    this.doOpenConfirm(
      () => {
        customEvent.emit(event);
      },
      () => {},
      "Unsaved Information",
      message
    );
  }

  isButtonEnebled(buttonType: ButtonType) {
    for (
      let index = 0, length = this.buttonConfig.length;
      index < length;
      index++
    ) {
      const element = this.buttonConfig[index];
      if (element.buttonType === buttonType && !element.disabled) {
        return true;
      }
    }

    return false;
  }
  callDelete(event) {
    for (
      let index = 0, length = this.buttonConfig.length;
      index < length;
      index++
    ) {
      const element = this.buttonConfig[index];
      if (
        element.customButton &&
        element.customButton.label === "End Process"
      ) {
        element.customButton.callback(event);
      }
    }
  }

  onFormValueChange(data: string): void {}

  yesCallback() {}

  onInitialize(): void {}

  onViewReady(): void {}

  onDestroy(): void {}
}
