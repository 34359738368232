import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";

@Pipe({ name: "ssn" })
export class SsnPipe implements PipeTransform {
  transform(value: any, format?: string): string {
    if (
      !value ||
      value === MyNjHelpsConstants.NULL_INT ||
      value === MyNjHelpsConstants.NULL_LONG
    ) {
      return "";
    }
    let ssnString = value.toString();
    if (ssnString.length > 0) {
      let part1 = "";
      let part2 = "";
      if (format === "unmask") {
        if (ssnString.length < 9) {
          for (let index = ssnString.length; index < 9; index++) {
            ssnString = "0" + ssnString;
          }
        }
        part1 = ssnString.slice(0, 3);
        part2 = ssnString.slice(3, 5);
      } else {
        part1 = "***";
        part2 = "**";
      }

      const part3 =
        ssnString.length <= 4
          ? this.formatZero(ssnString)
          : ssnString.slice(ssnString.length - 4, ssnString.length);
      const ssn = part1 + "-" + part2 + "-" + part3;

      return ssn;
    }

    return value;
  }

  formatZero(ssn: string) {
    let str = ssn;
    for (let index = ssn.length; index < 4; index++) {
      str = "0".concat(str);
    }

    return str;
  }
}
