import { BaseModel, MyNjHelpsConstants } from "@mynjhelps/common";

export class AddNavigationDetailInData extends BaseModel {
  caseId: number = MyNjHelpsConstants.NULL_LONG;
  docketIdc: string = "";
  personId: number = MyNjHelpsConstants.NULL_LONG;
  screenCode: string = "";
  screenFunctionCode: string = "";
  searchKeyJson: string = "";
  signedonOfficeCode: string = "";
  signedonUserName: string = "";
}
