import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ScreenFormComponent, IScreenInfo } from '@mynjhelps/uibase';
import { CommonService } from 'lib-mynjh-shared';

// import { CommonService } from '../../services/common.service';


@Component({
  selector: 'case-label',
  templateUrl: './case-label.component.html',
  styleUrls: ['./case-label.component.scss']
})

export class CaseLabelComponent extends ScreenFormComponent {

  @Input() labelText: string = "";  
  value: string = "";
  refmData: any;
  @Input() set valueText(val) {
    if (val) {
      this.value = this.commonService.checkIsEmpty(val);
    }
   
  }
  
  hide: boolean = false;
  @Input() type: number;
  @Input() indentLevel: number = 0;
  @Input() refm: string;
  @Input() options: any;
  @Input() nowrap: boolean = false;
  @Input() showOnEmpty: boolean = false;
  @Input() multiple: boolean = false;
  @Input() formatSsn: boolean = true;
  @Input() labelStyle: string = "";
  @Input() valueStyle: string = "";
  @Input() helptext: any = "";
  
  isMobileView: boolean = false;
  screenInfo: IScreenInfo;
  previousLang = "";
  isAdmin: boolean = false;
  onFormValueChange(data: any): void {  }

  onInitialize(): void {

    this.refmData = JSON.parse(this.storageService.getItem("STORE"));
    this.setDevice();
  }

  onViewReady(): void {
    this.setDevice();
  }

  onDestroy(): void {}

  constructor(private commonService: CommonService,
    ) {
    super();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setDevice();
  }

  /**
   * to check device
   */
  setDevice() {
    this.isMobileView = this.commonService.deviceWidthLessThan(480);
  }
 
  /**
   * get current language
   * @returns 
   */
  getLanguage() {
    let lang = this.storageService.getItem("Language");
    lang = (lang) ? lang.toUpperCase() : "EN";
    return lang;
  }

  /**
   * get refm by language
   * @returns 
   */
  getRefmOptions() {
    let lang = this.getLanguage();
    if (lang != this.previousLang) {
      this.previousLang = lang;
      if (
        this.refm &&
        this.refm !== undefined &&
        this.refmData.REFM[lang][this.refm].length !== 0
      ) {
        return this.refmData.REFM[lang][this.refm]
      } else {
        return [];
      }
    }
    else{
      return this.refmData.REFM[lang][this.refm];
    }
  }


}
