import { BaseModel, MyNjHelpsConstants } from "@mynjhelps/common";

export class UploadDocumentData extends BaseModel {

    BirthDate: string = MyNjHelpsConstants.LOW_DATE;

    ConfirmationID: string = "";
    CaseID: string = "";
    ConfirmationCaseIDIndc: string = "";
    FirstName: string = "";
    LastName: string = "";
    MiddleName: string = "";
    ContactEML: string = "";

}