import { Component, HostListener, Input } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import { IScreenInfo, ScreenComponent } from "@mynjhelps/uibase";
import { Subscription } from "rxjs";
import { appConfig } from "src/config";

@Component({
  selector: "app-main-menu",
  templateUrl: "./main-menu.component.html",
  styleUrls: ["./main-menu.component.scss"]
})
export class MainMenuComponent extends ScreenComponent {
  subsystem: string;
  navigationEventSubscription: Subscription;
  

  @Input() get system() {
    return this.subsystem;
  }
  set system(value) {
    this.selectActiveElement(value);
  }
  screenInfo: IScreenInfo = null;
  visibleStartProcess: boolean = false;
  visiblePostNote: boolean = false;
  visibleAddTask: boolean = false;
  isConfidential: boolean = false;

  private confidentialScreens: string[] = [
    "/BSTL/",
    "/CASE/",
    "/CBAT/",
    "/CPRO/",
    "/CREC/",
    "/DEMO/",
    "/DHLD/",
    "/DPRO/",
    "/DRRQ/",
    "/DSBV/",
    "/ELOG/",
    "/ICOR/",
    "/INSR/",
    "/NOTE/",
    "/OWIZ/",
    "/RHIS/",
    "/RPOS/",
    "/RPRO/",
    "/RREP/",
    "/SLOG/",
    "/SRCH/",
    "/TAXI/"
  ];
  actionListItems = [
    {
      label: "Start a Process",
      icon: "fa fa-list",
      command: () => {
        this.visibleStartProcess = true;
      }
    },
    {
      label: "Post a Note",
      icon: "fa fa-list",
      command: () => {
        this.visiblePostNote = true;
      }
    },
    {
      label: "Add a Task",
      icon: "fa fa-list",
      command: () => {
        this.visibleAddTask = true;
      }
    }
  ];
  keyPair = {
    73: 0,
    71: 1,
    66: 2,
    69: 3,
    70: 4,
    89: 5,
    84: 6,
    72: 7
  };
  constructor() {
    super();
  }

  quickNavigationOccured(event: any) {
    this.system = event;
  }
  screenCodes = {
    worker: ["WRKL"],
    case: ["CASE"],
    tools: ["PARM"],
    participants: ["PSOI"],
    soic: ["SOIN"]
  };

  subMenu = null;
  primaryMenu = [
    {
      label: "Worklist",
      icon: "nv-distributionHold_receiptsReport",
      routerLink: ["/worker/wrkl"]
    },
    {
      label: "Events",
      routerLink: ["/worker/events"],
      icon: "nv-events"
    },
    {
      label: "Case",
      icon: "nv-case1",
      routerLink: ["/case"],
      value: "case",
      command: (item) => {
        this.setSubMenu(item);
      }
    },
    {
      label: "Participant",
      icon: "nv-participant1",
      routerLink: ["/participant"],
      value: "participants",
      command: (item) => {
        this.setSubMenu(item);
      }
    },
    {
      label: "Source of Income",
      icon: "nv-source_income2",
      routerLink: ["/source-of-income"],
      value: "soic",
      command: (item) => {
        this.setSubMenu(item);
      }
    },
    {
      label: "Financial",
      icon: "nv-financial",
      routerLink: ["/financial"],
      value: "financial",
      command: (item) => {
        this.setSubMenu(item);
      }
    },
    {
      label: "Tools",
      icon: "nv-tools1",
      routerLink: ["/tools"],
      value: "tools",
      command: (item) => {
        this.setSubMenu(item);
      }
    },
    {
      label: "Help",
      icon: "nv-help4",
      routerLink: ["/help"],
      value: "help",
      command: (item) => {
        this.setSubMenu(item);
      }
    },
    {
      label: "Links",
      icon: "pi pi-fw nv-links",
      items: [
        {
          label: "Labcorp Website",
          icon: "fa fa-external-link",
          styleClass: "HeaderLinkMenu",
          url: "https://identilink.labcorp.com/identilink/login",
          target: "blank"
        },
        {
          label: "State Services Portal",
          icon: "fa fa-external-link",
          styleClass: "HeaderLinkMenu",
          url: "https://ocsp.acf.hhs.gov/csp/home/state",
          target: "blank"
        },
        {
          label: "Reports",
          icon: "fa fa-external-link",
          styleClass: "HeaderLinkMenu",
          url: "",//appConfig.sapBIUrl,
          target: "blank"
        },
        {
          label: "Intergovernmental Reference Guide",
          icon: "fa fa-external-link",
          styleClass: "HeaderLinkMenu",
          url: "https://ocsp.acf.hhs.gov/csp/home/state",
          target: "blank"
        }
      ],
      command: (item) => {}
    }
  ];

  private secondaryMenu = {
    worker: [],
    tools: [
      {
        label: "Maintenance",
        routerLink: ["/tools/maintenance"],
        icon: "pi pi-clone"
      },
      {
        label: "Portals",
        routerLink: ["/tools/portals"],
        icon: "pi pi-clone"
      }
    ],
    participants: [
      {
        label: "Search",
        icon: "fa fa-search",
        routerLink: ["/participant/participant-search"]
      },
      {
        label: "Participant Home",
        icon: "fa fa-child",
        routerLink: ["/participant/participant-home"]
      },
      {
        label: "Locate",
        icon: "fa fa-map-marker",
        routerLink: ["/participant/locate"]
      },
      {
        label: "Enforcement",
        icon: "fa fa-gavel",
        routerLink: ["/participant/enforcement"]
      }
    ],
    financial: [
      {
        label: "Receipts",
        routerLink: ["/financial/receipts"],
        icon: "pi pi-clone"
      },
      {
        label: "Disbursements",
        routerLink: ["disbursement"],
        icon: "pi pi-clone"
      },
      {
        label: "Reports",
        routerLink: ["/financial/reports"],
        icon: "pi pi-clone"
      }
    ],
    case: [
      {
        label: "Search",
        icon: "fa fa-search",
        routerLink: ["/case/search-result"],
        command: () => {
          localStorage.removeItem("isCaseSearch");
        }
      },
      {
        label: "Initiate Case",
        icon: "pi pi-folder-open",
        routerLink: ["/case/case-initiation"]
      },
      {
        label: "Case Info",
        icon: "pi pi-folder-open",
        routerLink: ["/case/case-info"]
      },
      {
        label: "Establishment",
        icon: "fa fa-child",
        routerLink: ["/case/establishment"]
      },
      {
        label: "Enforcement",
        icon: "fa fa-gavel",
        routerLink: ["/case/enforcement"]
      },
      {
        label: "Financials",
        icon: "fa fa-money",
        routerLink: ["/financial/receipts"]
      },
      {
        label: "Intergovernmental",
        icon: "fa fa-map-signs",
        routerLink: ["/case/intergovermental"]
      }
    ],
    help: [
      {
        label: "Contents",
        icon: "pi pi-clone",
        routerLink: ["/help/csep-contents"]
      },
      {
        label: "Index",
        icon: "pi pi-calendar"
      },
      {
        label: "Search",
        icon: "fa fa-search"
      },
      {
        label: "Glossary",
        icon: "fa fa-book"
      },
      {
        label: "CSEP Policy and Training Manuals",
        icon: "pi pi-folder-open",
        routerLink: ["/help/csep-policy"]
      }
    ],
    soic: [
      {
        label: "Source of Income Information",
        icon: "pi pi-user",
        routerLink: ["/source-of-income/soi-information"]
      }
    ]
  };

  private setSubMenu(selectedItem) {
    if (selectedItem && selectedItem.item) {
      this.subMenu = this.secondaryMenu[selectedItem.item.value];
    }
  }

  selectActiveElement(item?: any) {
    let activeUrl = "";
    if (item) {
      activeUrl = item.routerLink[0];
    } else {
      activeUrl = window.location.pathname;
    }

    const activeItem = this.primaryMenu.filter((item) => {
      if (item.routerLink && activeUrl.indexOf(item.routerLink[0]) != -1) {
        return item;
      }
    });
    this.setSubMenu({ item: activeItem[0] });
  }

  onInitialize(): void {
    this.navigationEventSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const pathname = event.urlAfterRedirects;
        const screenCode = pathname.split("/")[1];
        if (this.screenCodes[screenCode]) {
          this.subMenu = this.secondaryMenu[screenCode];
        }
        this.isConfidential = false;
        for (
          let index = 0, screenLength = this.confidentialScreens.length;
          index < screenLength;
          index++
        ) {
          if (
            event.urlAfterRedirects
              .toUpperCase()
              .indexOf(this.confidentialScreens[index]) !== -1
          ) {
            this.isConfidential = true;
            break;
          }
        }
        this.selectActiveElement();
      }
    });

    let isNavigate = false;
    for (const key in this.screenCodes) {
      if (this.screenCodes.hasOwnProperty(key)) {
        const element = this.screenCodes[key];
        if (element.indexOf(appConfig.defaultUrl) !== -1) {
          this.subMenu = this.secondaryMenu[key];
          isNavigate = true;
          return;
        }
      }
    }
    if (!isNavigate) {
      this.subMenu = this.secondaryMenu.worker;
    }
  }

  showHelp() {
    const helpInData = {
      screenCode: "",
      screenFunctionCode: "",
      screenSectionCode: "",
      controlName: ""
    };
    const screenInfo = JSON.parse(this.storageService.getItem("pageInfo"));
    helpInData.screenCode = screenInfo.screenName;
    helpInData.screenFunctionCode = screenInfo.screenFunction;
    this.messageService.toggleHelpInfo(helpInData);
  }

  onViewReady(): void {
    this.selectActiveElement();
  }
  onDestroy(): void {}
  @HostListener("document:keydown", ["$event"]) onKeyDown(
    event: KeyboardEvent
  ) {
    if (event.altKey && this.keyPair[event.which] !== undefined) {
      event.preventDefault();
      const menuItem = this.primaryMenu[this.keyPair[event.which]];
      if (menuItem.routerLink && menuItem.routerLink.length > 0) {
        this.doNavigate(menuItem.routerLink[0]);
        menuItem.command({ item: menuItem });
      }
    }
  }
}
