import { Injectable } from "@angular/core";
import { MyNjHelpsConstants, OutData, StickyDataSaveOut } from "@mynjhelps/common";
import { ApiService } from "@mynjhelps/http";
import { MessageService } from "@mynjhelps/message";
import { StorageService } from "@mynjhelps/storage";
import { AddNavigationDetailInData } from "@shared/model/screen";
import { StickyDataRetriveIn } from "@shared/model/sticky/sticky-data-retrive-in-data.model";
import { Observable } from "rxjs";
import screenFunctionUrl from "../../../assets/data/screen-function-code-url.json";
import { ScreenService } from "./screen.service";
import { StickyDataRetriveOut } from '@shared/model/sticky/sticky-data-retrive-out-data.model';
import { StickyDataSaveIn } from "@shared/model/sticky/sticky-data-save-in-data.model";


@Injectable()
export class StickyService {
  currentSticky: StickyDataSaveOut = new StickyDataSaveOut();
  private lastNavInData: AddNavigationDetailInData;
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private storageService: StorageService,
    private screenService: ScreenService
  ) {
    // const inData = new StickyInData();
    // inData.signedonUserName = "SSUBRAMANI";
    // this.updateSticky(inData);
    messageService.updateStickyData = this.updateSticky.bind(this);
  }
  getStickyData(
    stickydata: StickyDataRetriveIn
  ): Observable<OutData<StickyDataRetriveOut>> {
    var input =
    {
      "ID_SESSION": "202111912931188", "ID_SCREEN": "CPRO",
      "ID_WORKER_SIGNEDON": "WORKER2422", "TS_CREATE": null, "ID_CASE": "CS00000311D",
      "ID_CASE_WELFARE": "", "ID_MEMBER": "", "CD_RELATION_CASE": "", "NAME_LAST": "",
      "NAME_FIRST": "", "NAME_MI": "", "ID_MEMBER_WELFARE": "", "MEM_SSN": "",
      "NCP_ID_MEMBER": "", "NCP_SSN": "", "CP_ID_MEMBER": "", "CP_SSN": "", "ID_ORDER": "",
      "SEQ_ORDER": -2147483648, "ID_DOCKET": "", "SEQ_OBLIGATION": -2147483648, "DT_BEG_OBLIGATION": null,
      "CD_TYPE_DEBT": "", "DT_BATCH": null, "CD_SOURCE_BATCH": "", "NO_BATCH": -2147483648, "SEQ_RECEIPT": "",
      "CD_SOURCE_RECEIPT": "", "DT_RECEIPT": null, "ID_PAYOR": "", "CD_TYPE_REMITTANCE": "", "ID_CHECK_RECIPIENT": "",
      "CD_CHECK_RECIPIENT": "", "DT_DISBURSE": null, "MTH_WELFARE": -2147483648, "NO_CHECK": "", "SEQ_MAJOR_INT": -2147483648,
      "SEQ_MINOR_INT": -2147483648, "CD_ACTIVITY": "", "CD_ACTIVITY_MAJOR": "", "CD_ACTIVITY_MINOR": "", "CD_STATE_FIPS": "",
      "CD_TYPE_ADDRESS": "", "ID_OTHER_PARTY": "", "ID_FIPS": "", "ID_NOTICE": "", "DT_FROM": null, "DT_TO": null, "ID_TRANS_HEADER": "",
      "CD_OTH_STATE_FIPS": "", "DT_TRANSACTION": null, "CD_COUNTY": "", "CD_OFFICE": "", "CD_CASH_LOCATION": "", "ID_PLAINTIFF": ""
    }

    return this.apiService.Post(
      input,
      "stickyJsonDataRetrivestkyAction",
      "",
      {
        reportProgress: false
      },
      true
    );
  }
  saveStickyData(
    stickydata: StickyDataSaveIn
  ): Observable<OutData<StickyDataSaveOut>> {
    stickydata.ID_SESSION = this.storageService.getItem("access_token");
    var input = {

      "ID_SESSION": "3A047811BC96C7218B7098EBB7D9FEE5",
      "ID_SCREEN": "EFTI",
      "ID_WORKER_SIGNEDON": "WORKER2422",
      "CD_OFFICE_SIGNEDON": "900",
      "ID_WORKER": "WORKER2422",
      "TS_CREATE": null,
      "ID_CASE": "CS00000311D",
      "ID_CASE_WELFARE": "",
      "ID_MEMBER": "",
      "CD_RELATION_CASE": "",
      "NAME_LAST": "",
      "NAME_FIRST": "",
      "NAME_MI": "",
      "ID_MEMBER_WELFARE": "",
      "MEM_SSN": "",
      "NCP_ID_MEMBER": "",
      "NCP_SSN": "",
      "CP_ID_MEMBER": "",
      "CP_SSN": "",
      "ID_ORDER": "",
      "SEQ_ORDER": -2147483648,
      "ID_DOCKET": "",
      "SEQ_OBLIGATION": -2147483648,
      "DT_BEG_OBLIGATION": null,
      "CD_TYPE_DEBT": "",
      "DT_BATCH": null,
      "CD_SOURCE_BATCH": "",
      "NO_BATCH": -2147483648,
      "SEQ_RECEIPT": "",
      "CD_SOURCE_RECEIPT": "3",
      "DT_RECEIPT": null,
      "ID_PAYOR": "",
      "CD_TYPE_REMITTANCE": "",
      "ID_CHECK_RECIPIENT": "X00900311",
      "CD_CHECK_RECIPIENT": "",
      "DT_DISBURSE": null,
      "MTH_WELFARE": -2147483648,
      "NO_CHECK": "",
      "SEQ_MAJOR_INT": -2147483648,
      "SEQ_MINOR_INT": -2147483648,
      "CD_ACTIVITY": "",
      "CD_ACTIVITY_MAJOR": "",
      "CD_ACTIVITY_MINOR": "",
      "CD_STATE_FIPS": "",
      "CD_TYPE_ADDRESS": "",
      "ID_OTHER_PARTY": "",
      "ID_FIPS": "",
      "ID_NOTICE": "",
      "DT_FROM": null,
      "DT_TO": null,
      "ID_TRANS_HEADER": "",
      "CD_OTH_STATE_FIPS": "",
      "DT_TRANSACTION": null,
      "CD_COUNTY": "",
      "CD_OFFICE": "",
      "CD_CASH_LOCATION": "",
      "ID_JOB": "",
      "PACKAGE_NAME": "",
      "IND_HIS_NAVIGATION": "",
      "HISTORY_POSITION": -2147483648,
      "ID_TICKET": "",
      "CD_STATUS": "",
      "CD_SEVERITY": "",
      "ID_CASE_OTH_STATE": "",
      "SEQ_GUIDELINE": "",
      "ID_PLAINTIFF": "",
    }
    return this.apiService.Post(
      stickydata,
      "stickyJsonDataSavestkyAction",
      "",
      {
        reportProgress: false
      },
      true
    );
  }


  clear() {
    this.currentSticky = null;
    this.messageService.stickyData = null;
  }

  updateSticky(filterData: any, screenInfo?) {
    // this.getStickyData(null).subscribe((result)=>{   
    // })


    let stickyInData = new StickyDataSaveIn();
    stickyInData.copy(this.messageService.stickyData);
    let stickyChanged = false;
    for (const key in filterData) {
      if (
        filterData.hasOwnProperty(key) &&
        stickyInData[key] !== undefined &&
        stickyInData[key].toString() !== filterData[key].toString()
      ) {
        stickyInData[key] = filterData[key];
      }
    }

    stickyInData.ID_WORKER_SIGNEDON = this.messageService.signedOnWorkerId;
    if (screenInfo) {
      stickyInData.ID_SCREEN = screenInfo.screenName;
    }
    return this.refreshSticky(stickyInData);
    // }
  }

  refreshSticky(indata: StickyDataSaveIn): Promise<void> {
    return new Promise((resolve, reject) => {
      indata.ID_WORKER_SIGNEDON = this.messageService.signedOnWorkerId;
      indata.CD_OFFICE_SIGNEDON = this.messageService.signedOnWorkerOffice;
      indata.ID_WORKER = this.messageService.signedOnWorkerId;

      indata.ID_SESSION = "202111912931188";
      this.saveStickyData(indata).subscribe(
        (output: OutData<StickyDataSaveOut>) => {
          output.validateResponse(
            () => {
              this.messageService.stickyData = this.currentSticky;
            },
            () => { }
          );
          resolve();
        }
      );
    });
  }

  addNavigationDetails(screenInfo, filterData, filterInfo, activeUrl?) {
    let currentUrl = activeUrl;
    if (!currentUrl) {
      currentUrl = location.pathname;
    }

    const inData: AddNavigationDetailInData = new AddNavigationDetailInData();
    if (filterData) {
      inData.copy(filterData);
    }
    inData.screenCode = screenInfo.screenName;
    inData.signedonUserName = this.messageService.signedOnWorkerId;
    if (screenInfo.screenFunction && screenInfo.screenFunction !== "") {
      inData.screenFunctionCode = screenInfo.screenFunction;
    } else {
      inData.screenFunctionCode = this.getScreenfunctionCode(currentUrl);
    }
    if (filterInfo) {
      if (
        filterInfo.value.personId &&
        filterInfo.value.personId !== MyNjHelpsConstants.NULL_LONG
      ) {
        filterInfo.value.personId = filterInfo.value.personId.toString();
      }

      if (
        filterInfo.value.caseId &&
        filterInfo.value.caseId !== MyNjHelpsConstants.NULL_LONG
      ) {
        filterInfo.value.caseId = filterInfo.value.caseId.toString();
      }

      inData.searchKeyJson = JSON.stringify(filterInfo).replace(
        /-9223372036854776000/g,
        '"' + MyNjHelpsConstants.NULL_LONG_DATA + '"'
      );
    } else {
      return;
    }

    if (
      inData.personId === MyNjHelpsConstants.NULL_LONG &&
      inData.caseId === MyNjHelpsConstants.NULL_LONG &&
      inData.docketIdc === "" &&
      Object.keys(filterInfo.displayValue).length === MyNjHelpsConstants.NUMBER_0
    ) {
      return;
    }
    if (this.isNavDataChaged(inData) && inData.screenFunctionCode !== "") {
      if (
        inData.caseId !== null &&
        inData.caseId !== undefined &&
        (inData.caseId.toString() === "0" || inData.caseId.toString() === "")
      ) {
        inData.caseId = MyNjHelpsConstants.NULL_LONG;
      }
      if (
        inData.personId !== null &&
        inData.personId !== undefined &&
        (inData.personId.toString() === "0" ||
          inData.personId.toString() === "")
      ) {
        inData.personId = MyNjHelpsConstants.NULL_LONG;
      }
      this.screenService.addNavigationDetails(inData).subscribe((output) => {
        output.validateResponse(
          () => { },
          () => { }
        );
      });
    }
  }
  private getScreenfunctionCode(currentUrl) {
    const screenFnUrls = screenFunctionUrl;
    let screenFnCode = "";
    for (const key in screenFnUrls) {
      if (screenFnUrls[key] === currentUrl) {
        screenFnCode = key;

        return key;
      }
    }
    return screenFnCode;
  }
  private isNavDataChaged(currentInData: AddNavigationDetailInData) {
    if (this.lastNavInData) {
      for (const key in this.lastNavInData) {
        if (
          this.lastNavInData.hasOwnProperty(key) &&
          this.lastNavInData[key] !== currentInData[key]
        ) {
          return true;
        }
      }
      return false;
    }
    return true;
  }
}
