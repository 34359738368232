import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from "@angular/core";
import { MenuItem, MyNjHelpsConstants } from "@mynjhelps/common";
import { CustomPanelMenuComponent } from "@mynjhelps/custom-component";
import { IScreenInfo, ScreenComponent } from "@mynjhelps/uibase";
import screenFunctionUrl from "../../../../../assets/data/screen-function-code-url.json";
import { UtilityService } from '@mynjhelps/utility';
import { CommonService } from "lib-mynjh-shared";



@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.scss"]
})
export class LeftMenuComponent extends ScreenComponent {
  @ViewChild("panelmenu") panelmenu: CustomPanelMenuComponent;
  screenInfo: IScreenInfo;

  screenList = [];
  @Output() menuLoaded: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onMenuToggleClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Input() associatedScreens = [];
  @Input() get screenMenu() {
    return this.screenList;
  }
  set screenMenu(value) {
    if (value) {
      value = this.getAccessedList(value);
      this.screenList = value; //his.getMenuItems(value);
      const currentPage = JSON.parse(this.storageService.getItem("pageInfo"));
      if (currentPage && currentPage.screenName === "WRKL") {
        return;
      }
    }
  }

  @ViewChild("screenMenu") screenPanelMenu: CustomPanelMenuComponent;
  private screenFnAccessList = [];
  constructor(private renderer: Renderer2, private el: ElementRef, private commonService: CommonService) {
    super();
  }
  onMenuHeaderIconClick(event: Event) {
    this.onMenuToggleClick.emit(event);
  }

  getAccessedList(screenList) {
    return screenList;
    const currentPage = JSON.parse(this.storageService.getItem("pageInfo"));
    if (currentPage && currentPage.screenName === "WRKL") {
      return screenList;
    }
    const accessableList = [];
    for (let index = 0, length = screenList.length; index < length; index++) {
      const element = screenList[index];
      let screenFncode = "";
      if (element.routerLink) {
        screenFncode = this.getScreenfunctionCode(element.routerLink[0]);
        if (this.screenFnAccessList.indexOf(screenFncode) !== -1) {
          accessableList.push({
            expanded: element.expanded ? element.expanded : false,
            label: element.label,
            routerLink: element.routerLink,
            title: element.title
          });
        }
      } else {
        if (element.items) {
          const accessableListChild = this.getAccessedList(element.items);
          if (accessableListChild.length > 0) {
            accessableList.push({
              expanded: element.expanded ? element.expanded : false,
              label: element.label,
              routerLink: element.routerLink,
              title: element.title,
              items: accessableListChild
            });
          }
        }
      }
    }

    return accessableList;
  }

  private getScreenfunctionCode(url) {
    const screenFnUrls = screenFunctionUrl;
    let screenFnCode = "";
    for (const key in screenFnUrls) {
      if (screenFnUrls[key].indexOf(url) !== -1) {
        screenFnCode = key;

        return key;
      }
    }
    return screenFnCode;
  }

  addScreenInfoIcon() {
    setTimeout(() => {
      const screenMenuElement = this.el.nativeElement.getElementsByClassName(
        "screenMenu "
      );
      let childMenuMenuItems = [];
      let parentMenuItem = [];
      if (screenMenuElement && screenMenuElement.length > 0) {
        childMenuMenuItems = screenMenuElement[0].getElementsByClassName(
          "ui-menuitem-link"
        );
        parentMenuItem = screenMenuElement[0].getElementsByClassName(
          "ui-panelmenu-header-link"
        );
      }

      for (
        let menuIndex = 0, menuItemLength = childMenuMenuItems.length;
        menuIndex < menuItemLength;
        menuIndex++
      ) {
        const element = childMenuMenuItems[menuIndex];
        const menuInfo: HTMLSpanElement = this.renderer.createElement("span");
        menuInfo.classList.add("pi");
        menuInfo.classList.add("pi-info-circle");
        menuInfo.classList.add("menuInfoIcon");
        menuInfo.setAttribute("tabindex", "0");
        this.renderer.setAttribute(menuInfo, "tabindex", "0");
        this.renderer.listen(menuInfo, "click", (event: Event) => {
          event.preventDefault();
          event.stopImmediatePropagation();
          this.showScreenInfo(event, this.getChildMenuItem(element.text));
        });
        this.renderer.listen(menuInfo, "keydown", (event: KeyboardEvent) => {
          if (event.which === MyNjHelpsConstants.KEY_ENTER) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.showScreenInfo(event, this.getChildMenuItem(element.text));
          }
        });
        this.renderer.appendChild(element, menuInfo);
      }

      for (
        let menuIndex = 0, menuItemLength = parentMenuItem.length;
        menuIndex < menuItemLength;
        menuIndex++
      ) {
        const element = parentMenuItem[menuIndex];
        const menuInfo = this.renderer.createElement("span");
        menuInfo.classList.add("pi");
        menuInfo.classList.add("pi-info-circle");
        menuInfo.classList.add("menuInfoIcon");
        menuInfo.setAttribute("tabindex", "0");
        this.renderer.appendChild(element, menuInfo);
        this.renderer.listen(menuInfo, "click", (event: Event) => {
          event.preventDefault();
          event.stopImmediatePropagation();
          this.showScreenInfo(event, this.getParentMenuItem(element.text));
        });
        this.renderer.listen(menuInfo, "keydown", (event: KeyboardEvent) => {
          if (event.which === MyNjHelpsConstants.KEY_ENTER) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.showScreenInfo(event, this.getParentMenuItem(element.text));
          }
        });
      }
    }, 0);
    // this.screenPanelMenu.panelMenu.
  }
  getChildMenuItem(text: string) {
    for (
      let index = 0, screenMenuLength = this.screenList.length;
      index < screenMenuLength;
      index++
    ) {
      const element = this.screenList[index];
      if (element.items) {
        for (
          let childIndex = 0, childLength = element.items.length;
          childIndex < childLength;
          childIndex++
        ) {
          const childElement = element.items[childIndex];
          if (childElement.label === text) {
            return childElement;
          }
        }
      }
    }
  }
  getParentMenuItem(text: string) {
    for (
      let index = 0, screenMenuLength = this.screenList.length;
      index < screenMenuLength;
      index++
    ) {
      const element = this.screenList[index];
      if (element.label === text) {
        return element;
      }
    }
  }

  showScreenInfo(event, menuItem) {
  }

  getMenuItems(menuItems: MenuItem[], parentTitle?: string): any {
    for (let index = 0, length = menuItems.length; index < length; index++) {
      const menuItem: MenuItem = menuItems[index];
      if (parentTitle) {
        menuItem.title = parentTitle;
        menuItem.id = index.toString();
      }
      if (menuItem.items && menuItem.items.length > 0) {
        const childItems = menuItem.items as MenuItem[];
        menuItem.items = this.getMenuItems(childItems, menuItem.title);      
      }
    }

    return menuItems;
  }
  // @HostListener("contextmenu", ["$event"])
  // onRightClick(event) {
  //   event.preventDefault();
  //   const srcElement = event.srcElement as HTMLElement;
  // }

  @HostListener("document:keydown", ["$event"]) onKeyDown(event) {
    if (event.altKey) {
      if (
        (event.keyCode > 48 && event.keyCode < 57) ||
        (event.keyCode > 96 && event.keyCode < 105)
      ) {
        const substarct = event.keyCode > 48 && event.keyCode < 57 ? 48 : 96;
        if (event.shiftKey) {
          this.navigateToAssociateScreen(event.keyCode - substarct);
        } else {
          this.navigateToScreenFunction(event.keyCode - substarct);
        }
      }
      if (event.keyCode === 191) {
        this.showActiveScreenInfo();
      }
    }
  }

  showActiveScreenInfo() {
    const screenMenuElement = this.el.nativeElement.getElementsByClassName(
      "screenMenu "
    );
    const activeElement = screenMenuElement[0].getElementsByClassName(
      "ui-menuitem-link-active"
    );
    for (
      let index = 0, activeElementLength = activeElement.length;
      index < activeElementLength;
      index++
    ) {
      const element = activeElement[index];
      if (element.tagName === "A") {
        const menuInfoElement = element.getElementsByClassName("menuInfoIcon");
        menuInfoElement[0].click();
        break;
      }
    }
  }

  updateMenu(url: string, menulist) {
    const currentPage = JSON.parse(this.storageService.getItem("pageInfo"));
    if (currentPage && currentPage.screenName === "WRKL") {
      return;
    }
    let isFound = false;
    for (
      let parentIndex = 0, parentLength = menulist.length;
      parentIndex < parentLength;
      parentIndex++
    ) {
      const parentElement = menulist[parentIndex];
      menulist[parentIndex].expanded = false;
      if (
        this.screenPanelMenu &&
        this.screenPanelMenu.panelMenu &&
        this.screenPanelMenu.panelMenu.model[parentIndex] &&
        this.screenPanelMenu.panelMenu.model[parentIndex].expanded
      ) {
        this.screenPanelMenu.panelMenu.model[parentIndex].expanded = false;
      }
      if (parentElement.items && !isFound) {
        for (
          let childIndex = 0, childLength = parentElement.items.length;
          childIndex < childLength;
          childIndex++
        ) {
          const childElement = parentElement.items[childIndex];

          if (
            url.indexOf(childElement.routerLink[0]) !== -1 ||
            childElement.routerLink[0].indexOf(url) !== -1
          ) {
            if (
              this.screenPanelMenu &&
              this.screenPanelMenu.panelMenu &&
              this.screenPanelMenu.panelMenu.model
            ) {
              for (
                let menuIndex = 0,
                menuLength = this.screenPanelMenu.panelMenu.model.length;
                menuIndex < menuLength;
                menuIndex++
              ) {
                const element = this.screenPanelMenu.panelMenu.model[menuIndex];
                if (element.title === parentElement.title) {
                  this.screenPanelMenu.panelMenu.model[
                    menuIndex
                  ].expanded = true;
                }
              }
            }
            parentElement.expanded = true;
            isFound = true;
            break;
          }
        }
      }
    }

    setTimeout(() => {
      const screenMenuParentElement = this.el.nativeElement.getElementsByClassName(
        "screenMenu"
      );
      let activeElement = screenMenuParentElement[0].getElementsByClassName(
        "ui-menuitem-link ui-menuitem-link-active"
      );
      if (activeElement.length == 0) {
        activeElement = screenMenuParentElement[0].getElementsByClassName(
          "ui-panelmenu-header-link ui-menuitem-link-active"
        );
      }
      if (activeElement.length > 0) {
        activeElement[0].scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center"
        });
      }
    }, 0);
  }

  navigateToAssociateScreen(no: number) {
    if (
      this.associatedScreens.length > 0 &&
      this.associatedScreens[0].items.length >= no
    ) {
      const screenFnItems = this.associatedScreens[0].items[no - 1] as MenuItem;
      if (
        screenFnItems.routerLink &&
        screenFnItems.routerLink.length > 0 &&
        screenFnItems.routerLink[0] !== undefined
      ) {
        this.doNavigate(screenFnItems.routerLink[0]);
      } else {
        this.doShowMessage("The Page is Under Construction");
      }
    }
  }

  navigateToScreenFunction(no: number) {
    const activeScreen: MenuItem[] = this.screenMenu.filter(
      (item: MenuItem) => {
        if (item.expanded) {
          return item;
        }
      }
    );
    if (activeScreen.length > 0 && activeScreen[0].items.length >= no) {
      const screenFnItems = activeScreen[0].items as MenuItem[];
      this.doNavigate(screenFnItems[no - 1].routerLink[0]);
    }
  }

  onInitialize(): void {
    this.storageService.setItem(
      "SCREEN_FN_URL",
      JSON.stringify(screenFunctionUrl)
    );
    // this.commonService.getLeftMenuData().subscribe((output: any) => {
    //   this.loadLeftMenuData(output.GetScreenListOut.records.GetScreenListRecord)
    // })
  }
  collapseAll() {
    this.panelmenu.panelMenu.collapseAll();
  }
  loadLeftMenuData(menuserviceData) {
    let menuDataSource: MenuItem[] = []
    let screenFnNavInfo = {};
    let screenFnData = [];

    const mainMenu = UtilityService.convertGrouping(menuserviceData, "NMCAT");
    for (let index = 0; index < mainMenu.length; index++) {
      const element = mainMenu[index];
      if (element.name.length > 0) {
        const subGroup = UtilityService.convertGrouping(element.children, "NMSBCAT");
        let mainMenuItem: any = {
          label: element.name,
          value:element,
          icon: this.iconMenus[index],
          items: []
        }
        for (let subIndex = 0; subIndex < subGroup.length; subIndex++) {
          const subelement = subGroup[subIndex];
          let subMenuItem: any = {
            value:subelement,
            label: subelement.name,
            items: [],            
            command: (event) => {
              mainMenuItem.items.forEach(element => {
                element.expanded=false;
              });
              event.item.expanded=true;
            }
           
          }

          const subGroup2 = UtilityService.convertGrouping(subelement.children, "NMSSYT");

          for (let subIndex2 = 0, sub2Length = subGroup2.length; subIndex2 < sub2Length; subIndex2++) {
            const subelement2 = subGroup2[subIndex2];
            let subMenuItem2: any = {
              value:subelement2,
              label: subelement2.name,
              items: [],              
              command: (event) => {
                subMenuItem.items.forEach(element => {
                  element.expanded=false;
                  if(element.items){
                    element.items.forEach(childelement => {
                      childelement.expanded=false;
                    });
                  }
                });
                event.item.expanded=true;
              }
            }

            const subGroup3 = UtilityService.convertGrouping(subelement2.children, "NMSFN");
            for (let subGrp3Index = 0, subGrp3Length = subGroup3.length; subGrp3Index < subGrp3Length; subGrp3Index++) {
              const subelement3 = subGroup3[subGrp3Index];
              const screnfnValue = subelement3.children[0];
              screenFnData.push(screnfnValue);
              let subMenuItem3: any = {
                label: subelement3.name,
                value: screnfnValue,
                disabled: (screnfnValue.INDACCS === "N") ? true : false,                
                command: (event) => {
                  subMenuItem2.items.forEach(element => {
                    element.expanded=false;
                  });
                  if (screenFunctionUrl[event.item.value.CDSFN]) {
                    this.doNavigate(screenFunctionUrl[event.item.value.CDSFN]);
                    event.item.expanded=true;
                  }
                  

                }
              }

              subMenuItem2.items.push(subMenuItem3);
              screenFnNavInfo[screnfnValue.CDSFN] = [
                { label: mainMenuItem.label },
                { label: subMenuItem.label },
                { label: subMenuItem2.label },
                { label: subMenuItem3.label }
              ];
              if (subMenuItem2.label === "") {
                screenFnNavInfo[screnfnValue.CDSFN].splice(2,1);
              }
            }
            if (subMenuItem2.label === "") {
              subMenuItem.items = subMenuItem2.items;
            }
            else {
              subMenuItem2.items.sort(this.compare);
              subMenuItem.items.push(subMenuItem2);
            }
          }

          mainMenuItem.items.push(subMenuItem);
        }
        menuDataSource.push(mainMenuItem);
      }
    }
    // console.log(this.screenMenu);   
    this.screenMenu = menuDataSource;
    this.storageService.setItem("SCRN_FN_INFO", JSON.stringify(screenFnNavInfo));
    this.storageService.setItem("SCRN_FN_DATA", JSON.stringify(screenFnData));
    this.menuLoaded.emit();
  }
  iconMenus = [
    "nv-caseInitiation",
    "nv-caseManagement",
    "nv-financial2",
    "nv-portal",
    "nv-enforcement",
    " nv-establishment2",
    " nv-tools2",
    "nv-participant_programHistory",
    "nv-receipt_process",
    "nv-source_incomeMerge",
    "nv-worker",
    "nv-financial_eventLog"
  ];
  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const POSITIONA = a.value.POSITION;
    const POSITIONB = b.value.POSITION;

    let comparison = 0;
    if (POSITIONA > POSITIONB) {
      comparison = 1;
    } else if (POSITIONA < POSITIONB) {
      comparison = -1;
    }
    return comparison;
  }
  onViewReady(): void {

  }
  onDestroy(): void { }
}
