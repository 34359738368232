import {
  Directive,
  HostListener,
  Input,
  ElementRef,
  Renderer2
} from "@angular/core";
import { MessageService } from "@mynjhelps/message";
import { StorageService } from "@mynjhelps/storage";

@Directive({
  selector: "[controlSection]"
})
export class ControlSectionDirective {
  @Input("controlSection") section: string = "";
  constructor() {}
}
