import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { MessageModel } from "@mynjhelps/common";
import { MessageService } from "@mynjhelps/message";
import { StorageService } from "@mynjhelps/storage";
import { ScreenService } from "@shared/services/screen.service";
import { appConfig } from "src/config";
import screenFunctionUrl from "../../../assets/data/screen-function-code-url.json";

@Injectable({
  providedIn: "root"
})
export class AccessGuard implements CanActivate {
  private accessMessageCode: string = "E0439";
  private invalidMessage = "User does not exist in PDS";
  private navData = {};
  constructor(
    private router: Router,
    private storageService: StorageService,
    private screenService: ScreenService,
    private messageService: MessageService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let access = this.storageService.getItem("access");
    return true;
    const screenFunction = this.getScreenfunctionCode(state.url);

    if (access) {
      access = JSON.parse(access);
      if (access.screenFn === screenFunction) {
        if (access.accessData && access.accessData.accessInd) {
          return true;
        } else {
          return false;
        }
      } else {
        if (screenFunction !== "") {
          const naviData = this.router.getCurrentNavigation();
          if (naviData && naviData.extras && naviData.extras.state) {
            this.navData = this.router.getCurrentNavigation().extras.state;
          } else {
            this.navData = null;
          }
          if (!appConfig.checkAccess) {
            this.storageService.setItem(
              "access",
              JSON.stringify({
                screenFn: screenFunction,
                accessData: {
                  accessInd: true,
                  deleteInd: true,
                  insertInd: true,
                  selectInd: true,
                  updateInd: true
                }
              })
            );

            return true;
          } else {
            this.screenService
              .getScreenAccess(screenFunction)
              .subscribe((result) => {
                const output: any = result.result;
                if (output) {
                  this.storageService.setItem(
                    "access",
                    JSON.stringify({
                      screenFn: screenFunction,
                      accessData: output
                    })
                  );

                  if (output.accessInd) {
                    this.router.navigate([state.url], {
                      state: this.navData
                    });
                  } else {
                    this.showMessage();

                    return false;
                  }
                } else {
                  this.showMessage();

                  return false;
                }
              });
          }

          return false;
        }

        return false;
      }
    } else if (screenFunction !== "") {
      const naviData = this.router.getCurrentNavigation();
      if (naviData && naviData.extras && naviData.extras.state) {
        this.navData = this.router.getCurrentNavigation().extras.state;
      } else {
        this.navData = null;
      }

      if (!appConfig.checkAccess) {
        this.storageService.setItem(
          "access",
          JSON.stringify({
            screenFn: screenFunction,
            accessData: {
              accessInd: true,
              deleteInd: true,
              insertInd: true,
              selectInd: true,
              updateInd: true
            }
          })
        );

        return true;
      } else {
        this.screenService
          .getScreenAccess(screenFunction)
          .subscribe((result) => {
            let output: any = result.result;
            if (output) {
              this.storageService.setItem(
                "access",
                JSON.stringify({
                  screenFn: screenFunction,
                  accessData: output
                })
              );

              if (output.accessInd) {
                this.router.navigate([state.url], {
                  state: this.navData
                });
              } else {
                this.showMessage();

                return false;
              }
            } else {
              this.showMessage();

              return false;
            }
          });
      }

      return false;
    }

    return false;
  }
  private getScreenfunctionCode(url) {
    const screenFnUrls = screenFunctionUrl;
    let screenFnCode = "";
    for (const key in screenFnUrls) {
      if (
        screenFnUrls[key].indexOf(url) !== -1 ||
        url.indexOf(screenFnUrls[key]) !== -1
      ) {
        screenFnCode = key;

        return key;
      }
    }

    return screenFnCode;
  }

  private showMessage() {
    const errorMessages = JSON.parse(this.storageService.getItem("EMSG"));
    const messgae = new MessageModel();
    messgae.Code = "F";
    messgae.Description = errorMessages[this.accessMessageCode];
    this.messageService.ShowErrorDetails(messgae);
  }
}
