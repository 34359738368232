import { Component, EventEmitter, HostListener, Output, ViewChild } from "@angular/core";
import { AuthenticationService } from "@mynjhelps/authentication";
import { IScreenInfo, ScreenComponent } from "@mynjhelps/uibase";
import { CommonService } from 'lib-mynjh-shared';
import { StickyService } from "@shared/services/sticky.service";
import { addSeconds } from "date-fns";
import { interval } from "rxjs";
import { appConfig } from "../../../../../config";
import { ThemingService } from "../../../services/theming.service";

@Component({
  selector: "app-user-info",
  templateUrl: "./user-info.component.html",
  styleUrls: ["./user-info.component.scss"]
})
export class UserInfoComponent extends ScreenComponent {
  screenInfo: IScreenInfo = null;
  userid: string = "";
  dateTime: Date;
  userInfo:string="";
  showUploadImage: boolean = false;

  constructor(
    private themingService: ThemingService,
    private authService: AuthenticationService,
    private commonServie: CommonService,
    private stickyService: StickyService
  ) {
    super();
  }

  @Output() viewChanged = new EventEmitter<string>();
  @Output() onLogout = new EventEmitter<any>();

  @ViewChild("userInfoPanel") userInfoPanel: any;

  theme: string = "blue";
  mode: string = "Development";
  profileImageService: any;
  visibleManageProfile: boolean = false;
  private modes = {
    T: "Testing",
    D: "Development",
    S: "Training",
    P: "Production"
  };

  userInfoItems = [
    {
      label: "Manage Profile",
      url: "",//appConfig.manageProfileUrl,
      icon: "nv-profile",
      command: event => {
        this.userInfoPanel.hide();
       // window.open(appConfig.manageProfileUrl);
      }
    },
    {
      label: "Upload Profile Image",
      icon: "nv-profile",
      command: event => {
        this.userInfoPanel.hide();
        this.showUploadImage = true;
      }
    },
    // {
    //   label: "Scrollable Header",
    //   icon: "fa fa-header",
    //   command: event => {
    //     this.userInfoPanel.hide();
    //     this.viewChanged.emit(event.item.label);
    //     event.item.label = "Fixed Header";
    //   }
    // },

    {
      label: "Preferred Theme",
      icon: "nv-preferred_theme ",
      command: event => { },
      items: [
        {
          label: "Green",
          styleClass: "themesMenu Green",
          command: $event => {
            this.theme = "green";
            $event.item.icon = "fa fa-dot-circle-o";
            this.themingService.setTheme(this.theme);
            this.userInfoPanel.hide();
          }
        },
        {
          label: "Purple",
          icon: "fa fa-circle-o",
          styleClass: "themesMenu Pink",
          command: $event => {
            this.theme = "pink";
            $event.item.icon = "fa fa-dot-circle-o";
            this.themingService.setTheme(this.theme);
            this.userInfoPanel.hide();
          }
        },
        {
          label: "Blue",
          styleClass: "themesMenu Blue",
          command: $event => {
            this.theme = "blue";
            this.themingService.setTheme(this.theme);
            this.userInfoPanel.hide();
          }
        }
      ]
    },
    {
      label: "Logout from MyNJHelps",
      icon: "fa fa-power-off",
      styleClass: "logout",
      command: $event => {
        this.stickyService.clear();
        this.onLogout.emit();
      }
    }
  ];

  onOverlayHide() {
    this.storageService.setItem("theme", this.theme);
  }
  @HostListener("document:keydown", ["$event"]) onKeyDown(
    event: KeyboardEvent
  ) {
    if (event.altKey) {
      switch (event.which) {
        // Q
        case 81:
          this.onLogout.emit();
          break;
      }
    }
  }

  private resetThemeSelection() {
    for (
      let index = 0, length = this.userInfoItems[2]["items"].length;
      index < length;
      index++
    ) {
      this.userInfoItems[2]["items"][index].icon = "fa fa-circle-o";
    }
  }

  onUploadImage(image: string) {
    // const inputData = new SaveImageUserInData();
    // inputData.userProfileImg = image;
    // inputData.signedonUserName = this.messageService.signedOnWorkerId;
    // inputData.userName = this.messageService.signedOnWorkerId;
    // this.commonServie
    //   .SaveUserImage(inputData)
    //   .subscribe((result: OutData<string>) => {
    //     result.validateResponse(
    //       () => {
    //         this.showUploadImage = false;
    //         // this.profileImageService = this.commonServie.getUserImage(
    //         //   this.messageService.signedOnWorkerId
    //         // );
    //       },
    //       (status: statuss) => { }
    //     );
    //   });
  }

  onInitialize(): void {
    this.mode = this.modes[appConfig.environment];
  }
  onViewReady(): void {
    this.userInfo = this.storageService.getItem("WORKER_INFO");      
    
    this.dateTime = this.messageService.getSystemDate();
    interval(1000).subscribe(time => {
      this.dateTime = addSeconds(this.dateTime, 1);
    });
    this.theme = this.storageService.getItem("theme");
    if (!this.theme) {
      this.theme = "blue";
    }
    this.themingService.setTheme(this.theme);
    // this.profileImageService = this.commonServie.getUserImage(
    //   this.messageService.signedOnWorkerId
    // );
  }

  onDestroy(): void { }
}
