import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";

@Pipe({ name: "fax" })
export class FaxPipe implements PipeTransform {
  transform(faxNumber: any): string {
    if (faxNumber === 0 || faxNumber === MyNjHelpsConstants.NULL_LONG) {
      return "";
    } else if (faxNumber > 0 && faxNumber.toString().length === 10) {
      const cleaned = ("" + faxNumber).replace(/\D/g, "");
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return faxNumber;
  }
}
