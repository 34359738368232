import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";

@Pipe({ name: "dateAlone" })
export class DateTimeZonePipe implements PipeTransform {
  transform(value: any): string {
    if (
      !value ||
      value === MyNjHelpsConstants.LOW_DATE_TIME ||
      value === MyNjHelpsConstants.HIGH_DATE_TIME
    ) {
      return "";
    }
    return value.split("T")[0];
    
  }
}
