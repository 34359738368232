import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";
import { AccordionInData } from "@shared/model/accordion-in-data.model";
@Pipe({ name: "receiptPipe" })
export class ReceiptPipe implements PipeTransform {
  constructor() {}
  transform(receiptRecord: AccordionInData): string {
    let formatedReceipt = "";
    if (receiptRecord) {
      if (receiptRecord.batchDate) {
        const date = receiptRecord.batchDate;
        const formattedDate =
          date[MyNjHelpsConstants.NUMBER_5] +
          date[MyNjHelpsConstants.NUMBER_6] +
          "/" +
          date[MyNjHelpsConstants.NUMBER_8] +
          date[MyNjHelpsConstants.NUMBER_9] +
          "/" +
          date[MyNjHelpsConstants.NUMBER_0] +
          date[MyNjHelpsConstants.NUMBER_1] +
          date[MyNjHelpsConstants.NUMBER_2] +
          date[MyNjHelpsConstants.NUMBER_3];

        formatedReceipt = formatedReceipt + formattedDate;
      }
      if (receiptRecord.batchSourceCode) {
        formatedReceipt = formatedReceipt + "-" + receiptRecord.batchSourceCode;
      }
      if (receiptRecord.batchNumb) {
        let batchNum = receiptRecord.batchNumb.toString();
        switch (batchNum.length) {
          case MyNjHelpsConstants.NUMBER_1:
            batchNum = "000" + batchNum;
            break;
          case MyNjHelpsConstants.NUMBER_2:
            batchNum = "00" + batchNum;
            break;
          case MyNjHelpsConstants.NUMBER_3:
            batchNum = "0" + batchNum;
            break;
        }
        formatedReceipt =
          formatedReceipt +
          "-" +
          batchNum[MyNjHelpsConstants.NUMBER_0] +
          batchNum[MyNjHelpsConstants.NUMBER_1] +
          batchNum[MyNjHelpsConstants.NUMBER_2] +
          batchNum[MyNjHelpsConstants.NUMBER_3];
      }
      if (receiptRecord.receiptSeqNumb) {
        let seqnum = receiptRecord.receiptSeqNumb.toString();
        switch (seqnum.length) {
          case MyNjHelpsConstants.NUMBER_1:
            seqnum = "00000" + seqnum;
            break;
          case MyNjHelpsConstants.NUMBER_2:
            seqnum = "0000" + seqnum;
            break;
          case MyNjHelpsConstants.NUMBER_3:
            seqnum = "000" + seqnum;
            break;
          case MyNjHelpsConstants.NUMBER_4:
            seqnum = "00" + seqnum;
            break;
          case MyNjHelpsConstants.NUMBER_5:
            seqnum = "0" + seqnum;
            break;
        }
        formatedReceipt =
          formatedReceipt +
          "-" +
          seqnum[MyNjHelpsConstants.NUMBER_0] +
          seqnum[MyNjHelpsConstants.NUMBER_1] +
          seqnum[MyNjHelpsConstants.NUMBER_2] +
          "-" +
          seqnum[MyNjHelpsConstants.NUMBER_3] +
          seqnum[MyNjHelpsConstants.NUMBER_4] +
          seqnum[MyNjHelpsConstants.NUMBER_5];
      }
    }

    return formatedReceipt;
  }
}
