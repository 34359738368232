import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  FormGroupSection,
  MessageModel,
  MyNjHelpsConstants,
  OutData
} from "@mynjhelps/common";
import { ApiService } from "@mynjhelps/http";
import { MessageService } from "@mynjhelps/message";
import { StorageService } from "@mynjhelps/storage";
import { ScreenFormComponent } from "@mynjhelps/uibase";
import {
  AdvancedNavigationInData,
  HelpScreenRecordData,
  ScreenRecordData
} from "@shared/model";
import {
  AddNavigationDetailInData,
  AdvancedNavigationRecordData,
  LastAccessedUserRecordData
} from "@shared/model/screen";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class ScreenService {
  private accessMessageCode: string = "E0439";
  private helpUrl: string = "childsupport-api/api/screen/{0}/help?";
  private associateScreenUrl: string =
    "childsupport-api/api/screen/{0}/associatedscreens";
  private getQuickNavigationUrl: string =
    "childsupport-api/api/screen/{0}/navigation/advanced";

  private validateScreenUrl: string =
    "childsupport-api/api/screen/{0}/validate/navigation";

  private addNavigationDetailsUrl: string =
    "childsupport-api/api/screen/searchkey";
  private getNavigationDetailUrl: string =
    "childsupport-api/api/screen/{0}/searchkey";
  private validateCaseUrl: string =
    "childsupport-api/api/common/vali/active/case/";
  private validatePersonUrl: string =
    "childsupport-api/api/common/vali/active/participant/";
  private caseErrorCode: string = "E0073";
  private participantErrorCode: string = "E2476";
  private errorMessages = {};
  private defaultValues: Array<string | number> = [
    null,
    MyNjHelpsConstants.NULL_INT,
    MyNjHelpsConstants.NULL_LONG
  ];
  private noValidateScreens: string[] = [
    "PMRG",
    "WRKL",
    "DSBV",
    "OBAA",
    "CASE",
    "DHLD",
    "RPRO",
    "RSTR",
    "EDOC",
    "SPRO",
    "LRQS",
    "NOTE",
    "DRRQ",
    "VISI",
    "LPDR",
    "CPER",
    "CADD",
    "CHIN",
    "CALT",
    "CSOI",
    "CDOC",
    "COLN",
    "CPWI",
    "EPWI",
    "CIBX",
    "EMEI",
    "EMIN",
    "ENMS",
    "EVEM",
    "CCSU",
    "DPRO"
  ];

  private noValidateScreenFunction = ["EFTIEIOA"]
  private participantScreens: string[] = [
    "AHIS",
    "PLIC",
    "PSOI",
    "PAST",
    "INEX",
    "EFTI",
    "DEMO",
    "INSR",
    "HLDI"
  ];
  private caseScreens: string[] = [
    "COMP",
    "OWIZ",
    "BWLT",
    "BHIS",
    "DCKT",
    "GTST",
    "ASMT",
    "CPRO",
    "ICOR",
    "GDLN",
    "CREC",
    "TAXI",
    "HLDI"
  ];
  constructor(
    private apiService: ApiService,
    private messageService: MessageService,
    private storageService: StorageService
  ) {
    //this.messageSevice.validateScreenIds = this.checkScreen;
    messageService.validateIds = this.checkScreen.bind(this);
  }

  getAssociateScreenList(
    screenCode: string
  ): Observable<OutData<ScreenRecordData[]>> {
    return this.apiService.Get(
      this.associateScreenUrl.format(screenCode),
      null,
      {},
      true
    );
  }

  getAdvancedQuickNavigationDetails(
    screenCode: string,
    inData: AdvancedNavigationInData
  ): Observable<OutData<AdvancedNavigationRecordData>> {
    const queryString = inData.getQueryString();

    return this.apiService.Get(
      this.getQuickNavigationUrl.format(screenCode) + "?" + queryString
    );
  }

  validateScreen(
    screenCode: string,
    queryString: string
  ): Observable<OutData<string>> {
    return this.apiService.Get(
      this.validateScreenUrl.format(screenCode) + queryString
    );
  }

  getScreenHelpData(
    screenCode: string,
    helpInData: string
  ): Observable<OutData<HelpScreenRecordData>> {
    return this.apiService.Get(this.helpUrl.format(screenCode) + helpInData);
  }

  addNavigationDetails(
    input: AddNavigationDetailInData
  ): Observable<OutData<string>> {
    return this.apiService.Post(
      input,
      this.addNavigationDetailsUrl,
      "",
      {
        reportProgress: false
      },
      true
    );
  }

  getNavigationDetails(
    screenCode: string,
    screenFnCode?: string
  ): Observable<OutData<LastAccessedUserRecordData>> {
    if (screenFnCode) {
      return this.apiService.Get(
        this.getNavigationDetailUrl.format(screenCode) +
        "?screenFunctionCode=" +
        screenFnCode,
        "",
        {},
        true
      );
    } else {
      return this.apiService.Get(
        this.getNavigationDetailUrl.format(screenCode),
        "",
        {},
        true
      );
    }
  }

  validateCaseId(caseId: string): Observable<OutData<string>> {
    return this.apiService.Get(this.validateCaseUrl + caseId);
  }
  validateparticipantId(personId: string): Observable<OutData<string>> {
    return this.apiService.Get(this.validatePersonUrl + personId);
  }

  checkScreen(caseId?: string, personId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      let caseIdValue = null;
      let personIdValue = null;
      let isCaseRequired: boolean = false;
      let isPersonRequired: boolean = false;
      let isForm = false;

      // 24004

      const activePageInfo = JSON.parse(
        this.storageService.getItem("pageInfo")
      );
      if (this.noValidateScreens.indexOf(activePageInfo.screenName) !== -1 || this.noValidateScreenFunction.indexOf(activePageInfo.screenFunction) !== -1) {
        resolve();

        return;
      }
      if (caseId) {
        caseIdValue = caseId;
      } else if (personId) {
        personIdValue = personId;
      } else {
        isForm = true;
        const activeScreen: ScreenFormComponent = this.messageService
          .activeScreen;
        let filterValue = null;

        let formGroup: FormGroup = null;
        if (activeScreen.filterFormGroup) {
          filterValue = activeScreen.filterFormGroup.getRawValue();
          formGroup = activeScreen.filterFormGroup;
        } else if (
          activeScreen.rootFormGroup &&
          activeScreen.rootFormGroup.formSection ===
          FormGroupSection.filterSection
        ) {
          filterValue = activeScreen.rootFormGroup.getRawValue();
          formGroup = activeScreen.rootFormGroup;
        }
        if (filterValue) {
          if (filterValue["caseId"]) {
            caseIdValue = filterValue["caseId"];
            isCaseRequired = formGroup.controls.caseId.isrequired;
          }
          if (filterValue["personId"]) {
            personIdValue = filterValue["personId"];
            isPersonRequired = formGroup.controls.personId.isrequired;
          }
          if (filterValue["payorId"]) {
            personIdValue = filterValue["payorId"];
            isPersonRequired = formGroup.controls.payorId.isrequired;
          }
          if (filterValue["checkRecipientIdc"]) {
            personIdValue = filterValue["checkRecipientIdc"];
            isPersonRequired = formGroup.controls.checkRecipientIdc.isrequired;
          }
        }
      }
      let isCase = false;
      let isperson = false;

      if (this.caseScreens.indexOf(activePageInfo.screenName) !== -1) {
        isCase = true;
      } else if (
        this.participantScreens.indexOf(activePageInfo.screenName) !== -1
      ) {
        isperson = true;
      }
      if (activePageInfo.screenName === "RREP") {
        if (!personIdValue || personIdValue === MyNjHelpsConstants.NULL_LONG) {
          resolve();

          return;
        } else {
          isCase = true;
        }
      }
      if (activePageInfo.screenName === "HLDI") {
        let isPersonId = false;
        let isCaseId = false;
        if (personIdValue && personIdValue !== MyNjHelpsConstants.NULL_LONG) {
          isPersonId = true;
        }
        if (caseIdValue && caseIdValue !== MyNjHelpsConstants.NULL_LONG) {
          isCaseId = true;
        }
        if (isPersonId && !isCaseId) {
          isCase = false;
          isperson = true;
        } else if ((!isPersonId && isCaseId) || (isPersonId && isCaseId)) {
          isCase = true;
          isperson = false;
        }
      }
      if (
        caseIdValue &&
        this.defaultValues.indexOf(caseIdValue) === -1 &&
        caseIdValue.toString().length === MyNjHelpsConstants.NUMBER_10 &&
        isCase
      ) {
        this.validateCaseId(caseIdValue).subscribe(output => {
          output.validateResponse(
            () => {
              if (output.result === "Y") {
                resolve();
              } else {
                const msg = {
                  type: "Info",
                  formErrrors: null,
                  title: "Failure",
                  code: "F",
                  description: this.errorMessages[this.caseErrorCode]
                };
                this.messageService.ShowErrorDetails(msg);
                reject();
              }
            },
            () => {
              reject();
            }
          );
        });
      } else if (
        personIdValue &&
        this.defaultValues.indexOf(personIdValue) === -1 &&
        personIdValue.toString().length === MyNjHelpsConstants.NUMBER_10
      ) {
        this.validateparticipantId(personIdValue).subscribe(output => {
          output.validateResponse(
            () => {
              if (output.result === "Y") {
                resolve();
              } else {
                const msg = {
                  type: "Info",
                  formErrrors: null,
                  title: "Failure",
                  code: "F",
                  description: this.errorMessages[this.participantErrorCode]
                };
                this.messageService.ShowErrorDetails(msg);

                reject();
              }
            },
            () => {
              reject();
            }
          );
        });
      } else {
        resolve();
      }
    });
  }
  checkAccess(type) {
    const currentPageInfo = JSON.parse(this.storageService.getItem("pageInfo"));
    const accessInfo = JSON.parse(this.storageService.getItem("access"));
    if (currentPageInfo.screenFunction === accessInfo.screenFn) {
      const accessData = accessInfo.accessData;
      if (accessData[type]) {
        return true;
      }
    }
    this.showAccessMessage();

    return false;
  }

  showAccessMessage() {
    const messgae = new MessageModel();
    messgae.Code = "F";
    messgae.Description = this.errorMessages[this.accessMessageCode];
    this.messageService.ShowErrorDetails(messgae);
  }
  getScreenAccess(screenFnCode: string) {
    return this.apiService.Get(
      "oauth/access/" + screenFnCode,
      "",
      {},
      true,
      true
    );
  }

  getScreenAccessList() {
    return this.apiService.Get("oauth/pages/list", "", {}, true, true);
  }

  //   "CASE", -Manage case
  // "DHLD", -Case id conditionally required
  // "RPRO", - Held Receipts screen function
  // "RREP", -Both are optional,but participant alone conditionally required
}
