import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";

@Pipe({ name: "translates" })
export class TranslatesPipe implements PipeTransform {
  transform(text: any): string {
  
    return text;
  }
}
