import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";
import { UtilityService } from "@mynjhelps/utility";

@Pipe({ name: "phonePipe" })
export class FormatPhonePipe implements PipeTransform {
  constructor() { }
  transform(
    record: { [key: string]: string },
    phoneObj: { [key: string]: string },
    ext?: string
  ): string {
    if (!phoneObj) {
      return "";
    }

    const defaultphoneObj = {
      stdNumb: "stdNumb",
      phoneNumb: "phoneNumb",
      deviceTypeCode: "deviceTypeCode",
      phoneExtnNumb: "phoneExtnNumb"
    };

    UtilityService.copyValues(defaultphoneObj, phoneObj);

    const phoneRecord: { [key: string]: string | number } = {};

    for (const key in defaultphoneObj) {
      if (record.hasOwnProperty(defaultphoneObj[key])) {
        phoneRecord[key] = record[defaultphoneObj[key]];
      } else {
        phoneRecord[key] = "";
      }
    }
    if (phoneRecord.phoneNumb === "") {
      return "";
    }
    phoneRecord.phoneNumb = phoneRecord.phoneNumb.toString();
    let formatedPhoneNo = "";

    if (phoneRecord.stdNumb > 0) {
      formatedPhoneNo += " " + "+" + phoneRecord.stdNumb + " ";
    }
    if (
      phoneRecord.stdNumb &&
      phoneRecord.stdNumb.toString() === "1" &&
      phoneRecord.phoneNumb &&
      phoneRecord.phoneNumb.length === 10
    ) {
      const match = phoneRecord.phoneNumb.match(/^(\d{3})(\d{3})(\d{4})$/);
      formatedPhoneNo += "(" + match[1] + ")" + " " + match[2] + "-" + match[3];
    } else {
      formatedPhoneNo += phoneRecord.phoneNumb;
    }

    if (phoneRecord.deviceTypeCode) {
      formatedPhoneNo += "(" + phoneRecord.deviceTypeCode + ")";
    }
    if (ext && phoneRecord.phoneExtnNumb) {
      // phoneRecord.phoneExtnNumb = this.formatExt(phoneRecord.phoneExtnNumb);
      formatedPhoneNo += " " + "Ext." + " " + phoneRecord.phoneExtnNumb;
    }

    return formatedPhoneNo;
  }

  formatExt(extNumb: string | number) {
    let ext = extNumb.toString();
    for (let index = ext.length; index < 3; index++) {
      ext = "0".concat(ext);
    }

    return ext;
  }
}
