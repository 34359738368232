import { Component, EventEmitter, HostListener, Input, QueryList, Renderer2, TemplateRef, ViewChild, ViewChildren, Output } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NavigationEnd, NavigationStart } from "@angular/router";
import { FormGroupSection, MessageModel, MyNjHelpsConstants, OutData, ScreenType, EventArgs } from "@mynjhelps/common";
import { CustomInputComponent, CustomOneLookupComponent, CustomOverlayComponent, CustomSelectComponent, DateComponent, AutoCompleteComponent } from "@mynjhelps/custom-component";
import { IScreenInfo, ScreenComponent, ScreenFormComponent } from "@mynjhelps/uibase";
import { ScreenRecordData } from "@shared/model/screen/screen-record-data.model";
import { ScreenService } from "@shared/services/screen.service";
import { StickyService } from "@shared/services/sticky.service";
import { Subscription } from "rxjs";
import { appConfig } from "src/config";
import { LeftMenuComponent } from "../left-menu/left-menu.component";


@Component({
  selector: "app-screen-container",
  templateUrl: "./screen-container.component.html",
  styleUrls: ["./screen-container.component.scss"]
})
export class ScreenContainerComponent extends ScreenComponent {
  private pageInfo = [];
  activeScreenHeader = "";
  quickMenuText;
  screenData = [];
  isUpdateScreen = false;
  isAddScreen = false;
  isDeleteScreen = false;
  private sessionExpiredMsg =
    "Your session is expired. Please click ok to log back in or close this window.";
  private sessionHeader = "Session Warning";
  private f1KeyCode: number = 112;
  private screenPageComponent: ScreenFormComponent | ScreenComponent | any;
  activePageInfo: IScreenInfo;
  prevPageInfo: IScreenInfo;
  private lastScreenCode = "";
  private navigationEventSubscription: Subscription;
  private toggleFilterSubscription: Subscription;
  private subscriptions: Subscription[] = [];
  isMenuminimised = false;
  @Input() screenInfo: IScreenInfo;
  @Output() onViewChange = new EventEmitter();
  @ViewChild("screensAutoCompElement") screensAutoCompElement: AutoCompleteComponent;
  @ViewChild("selectFilterbtn") selectFilterbtn: any;
  @ViewChild("selectFilterPanel") selectFilterPanel;
  @ViewChild("pageSplitArea") pageSplitArea: any;
  @ViewChild("menuSplitArea") menuSplitArea: any;
  @ViewChild("filter") filterArea: any;
  @ViewChild("leftmenu")
  leftmenu: LeftMenuComponent;
  @ViewChildren(CustomSelectComponent)
  selectComponents: QueryList<CustomSelectComponent>;
  @ViewChildren("lib-input") inputComponents: QueryList<CustomInputComponent>;
  @ViewChildren("lib-date-input") dateInputComponents: QueryList<DateComponent>;
  @ViewChildren("lib-lookup")
  lookupComponents: QueryList<CustomOneLookupComponent>;
  // @ViewChild("screenMenu") screenMenu: CustomPanelMenuComponent;
  private defaultValues: any[] = [
    null,
    MyNjHelpsConstants.NULL_INT,
    MyNjHelpsConstants.NULL_LONG,
    MyNjHelpsConstants.LOW_DATE_TIME,
    MyNjHelpsConstants.LOW_DATE,
    MyNjHelpsConstants.LOW_DATE_MM_DD_YYYY,
    MyNjHelpsConstants.HIGH_DATE,
    MyNjHelpsConstants.HIGH_DATE_TIME,
    MyNjHelpsConstants.HIGH_DATE_MM_DD_YYYY
  ];
  private navigationServicepending = false;
  private menuSplitAreaScrollFn;
  private pageSplitAreaScrollFn;
  filterHeight = 0;
  isShowMenu = true;
  pageList = [];
  associatedScreens = [
    {
      label: "Associated Screens",
      styleClass: "associatedMenuHeader",
      expanded: true,
      items: []
    }
  ];
  private activeUrl: string = "";

  showFilterTemplate = false;
  isShowFilterBtn = true;
  isFilterBtn: boolean = true;
  breadcrumbItems = [];
  activeChild: any;
  filterTemplate: TemplateRef<any>;
  filterCount: number = 0;
  private menuSize: number = 19;
  private screenSize: number = 81;
  filterAreaWidth: number = 82;
  filterInfo = null;
  reloadFilterTemplate = false;

  area = {
    menu: this.menuSize,
    screen: this.screenSize
  };
  private accessMessageCode: string = "E0439";

  constructor(
    private renderer: Renderer2,
    private screenService: ScreenService,
    private stickyService: StickyService
  ) {
    super();

    this.subscriptions.push(
      this.messageService.updateSticky$.subscribe((formGroup: FormGroup) => {
        //this.updateSticky();
      })
    );
    this.subscriptions.push(
      this.messageService.toggleSelectFilter$.subscribe((isOpen) => {
        this.messageService.isFilterServiceInprogress = false;
        this.toggleOverlay(isOpen);

      })
    );
    this.subscriptions.push(
      this.messageService.toggleLeftMenu$.subscribe((isOpen) => {
        this.isShowMenu = isOpen;
      })
    );
    this.subscriptions.push(
      this.messageService.toggleSelectFilterButton$.subscribe((show) => {
        this.isFilterBtn = show;
        if (show && this.reloadFilterTemplate) {
          this.setScreenInfo(true);
        }
      })
    );
    this.subscriptions.push(
      this.messageService.onSearch$.subscribe((filter) => {
        if (this.screenPageComponent.advanceSearch) {
          this.screenPageComponent.advanceSearch(filter);
        }
      })
    );
  }

  toggleOverlay(isShow) {
    if (this.filterTemplate) {
      if (isShow) {
        this.showFilterTemplate = true;
        if (this.selectFilterbtn) {
        }
      } else {
      }
    }
  }

  // @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event): void {
    if (
      event.keyCode === MyNjHelpsConstants.KEY_ENTER &&
      event.target.tagName === "INPUT"
    ) {
      setTimeout(() => {
        // if (this.checkAccess("selectInd")) {
        this.onFilterSearch(event);
        //}
      }, 0);
    }
  }
  exitApplication() {
    const sticky = { state: { data: { session: "USER SESSION EXPIRED" } } };

    this.router.navigate(["/login"], sticky);
  }

  checkAccess(type) {
    const currentPageInfo = JSON.parse(this.storageService.getItem("pageInfo"));
    const accessInfo = JSON.parse(this.storageService.getItem("access"));

    if (!accessInfo || !currentPageInfo) {
      this.doOpenConfirm(
        () => { },
        null,
        this.sessionHeader,
        this.sessionExpiredMsg
      );

      return;
    }
    if (currentPageInfo.screenFunction === accessInfo.screenFn) {
      const accessData = accessInfo.accessData;
      if (accessData[type]) {
        return true;
      }
      this.showAccessMessage();
    }

    return false;
  }
  showAccessMessage() {
    const errorMessages = JSON.parse(this.storageService.getItem("EMSG"));
    const messgae = new MessageModel();
    messgae.Code = "F";
    messgae.Description = errorMessages[this.accessMessageCode];
    this.messageService.ShowErrorDetails(messgae);
  }

  onActivate(componenrRef) {
    this.activeChild = componenrRef;
  }

  resetMenu() { }

  showMenu() {
    this.onGutterClick(null);
  }
  hideMenu() {
    this.onGutterClick(null);
  }
  onSelectFilterClick(event) {
  }

  updateFilterCount() {
    return;
    if (
      this.filterTemplate &&
      (this.screenPageComponent as ScreenFormComponent) !== null &&
      ((this.screenPageComponent as ScreenFormComponent).rootFormGroup ||
        (this.screenPageComponent as ScreenFormComponent).filterFormGroup)
    ) {
      const filterReportData = (this
        .screenPageComponent as ScreenFormComponent).getReportFilter();
      const screenPage = this.screenPageComponent as ScreenFormComponent;
      const filterData = screenPage.filterFormGroup
        ? screenPage.filterFormGroup.getRawValue()
        : screenPage.rootFormGroup.value;
      const filterList = {};
      const orderedFilter = [];
      const labelTexts = [];
      const labels: HTMLLabelElement[] = this.filterArea.nativeElement.getElementsByTagName(
        "label"
      );
      for (let index = 0, length = labels.length; index < length; index++) {
        let lblText = labels[index].innerText;
        lblText = lblText.replace("*", "");
        lblText = lblText.trim();
        labelTexts.push(lblText);
      }

      this.filterCount = filterReportData["filterCount"];
      for (const filterKey in filterReportData) {
        if (
          filterReportData[filterKey] &&
          filterReportData[filterKey] !== "" &&
          filterKey !== "filterCount"
        ) {
          const label = filterKey;
          const value = filterReportData[filterKey]
            ? filterReportData[filterKey].toString()
            : "";
          if (this.defaultValues.indexOf(value) === -1 && value !== "") {
            orderedFilter[labelTexts.indexOf(label)] = {
              label,
              value: value.toUpperCase()
            };
            // filterList[label] = {
            //   label,
            //   value: value.toUpperCase()
            // };
          }
        }
      }
      for (
        let index = 0, filterlength = orderedFilter.length;
        index < filterlength;
        index++
      ) {
        const element = orderedFilter[index];
        if (element) {
          filterList[element.label] = element;
        }
      }
      this.storageService.setItem("SELECT_FILTER", JSON.stringify(filterList));
      this.filterInfo = { displayValue: filterList, value: filterData };
    } else {
      this.storageService.removeItem("SELECT_FILTER");
      this.filterInfo = null;
    }
  }

  onFilterSearch(event: Event) {
    //event.stopImmediatePropagation();

    this.screenPageComponent.search(event);

    this.updateFilterCount();
    event.stopImmediatePropagation();
    // if (this.checkAccess("selectInd")) {
    //   setTimeout(() => {

    //   }, 0);
    // }
  }

  updateSticky(screenInfo?) {
    // this.addNavigationDetails();
    if (this.screenPageComponent instanceof ScreenFormComponent) {
      if (
        this.screenPageComponent.filterFormGroup &&
        this.screenPageComponent.filterFormGroup.valid
      ) {
        this.stickyService.updateSticky(
          this.screenPageComponent.filterFormGroup.getRawValue(), screenInfo
        ).then(() => {
          this.refreshPage(true);
        }).catch(() => {

        });
      } else if (
        this.screenPageComponent.rootFormGroup &&
        this.screenPageComponent.rootFormGroup.valid &&
        this.screenPageComponent.rootFormGroup.formSection ===
        FormGroupSection.filterSection
      ) {
        this.stickyService.updateSticky(
          this.screenPageComponent.rootFormGroup.getRawValue(), screenInfo
        ).then(() => {
          this.refreshPage(false);
        }).catch(() => {

        });
      }
    }
  }

  refreshPage(isFilterForm) {
    let formGrp: FormGroup = this.screenPageComponent.rootFormGroup;
    if (isFilterForm) {
      formGrp = this.screenPageComponent.filterFormGroup;
    }
    let formValues = {};
    let isSticky = false;
    if (formGrp && formGrp.controls) {
      for (const key in formGrp.controls) {
        let stickyFieldValue = this.messageService.stickyData[key];
        if (formGrp.controls.hasOwnProperty(key) &&
          stickyFieldValue && stickyFieldValue !== "" &&
          stickyFieldValue.toString() !== MyNjHelpsConstants.NULL_INT.toString()) {
          formValues[key] = stickyFieldValue;
          isSticky = true;
        }
        else {
          formValues[key] = "";
        }
      }
      if (isSticky) {
        (this.screenPageComponent as ScreenFormComponent).doFormReset(formValues, formGrp, true);
        this.screenPageComponent.search();
      }
    }
  }

  onFilterReset(event) {
    //if (this.checkAccess("selectInd")) {
    this.messageService.ShowErrorDetails([]);
    this.screenPageComponent.reset(event);
    this.updateFilterCount();
    //}
  }
  onOverlayShow() {
    this.showFilterTemplate = true;
    this.setFocus();
    if (
      this.screenPageComponent &&
      this.screenPageComponent.onSelectFilterShown
    ) {
      this.screenPageComponent.onSelectFilterShown();
    }
  }

  setFocus() {
    const componenntsList = [
      this.inputComponents,
      this.dateInputComponents,
      this.selectComponents,
      this.lookupComponents
    ];
    for (
      let index = 0, componentLength = componenntsList.length;
      index < componentLength;
      index++
    ) {
      const components: QueryList<any> = componenntsList[index];
      for (
        let ctrlIndex = 0, controlLength = components.length;
        ctrlIndex < controlLength;
        ctrlIndex++
      ) {
        const element = components[ctrlIndex];
        if (element.autofocus) {
          setTimeout(() => {
            element.focus();
          }, 0);

          return;
        }
      }
    }
  }

  onOverlayHide() {
    this.filterHeight = 0;
    this.updateFilterCount();
    this.showFilterTemplate = false;
  }

  onGutterClick(event) {
    if (this.area.menu >= 19) {
      this.area.menu = 2.5;
      this.area.screen = 97.5;
      this.isMenuminimised = true;
      this.leftmenu.collapseAll();
      //   this.pageList=this.iconMenus;
      //  this.hideMenu();
    } else {

      this.area.menu = this.menuSize;
      this.area.screen = this.screenSize;
      this.isMenuminimised = false;



      //  this.pageList=this.labelMenu;
      //this.showMenu();
    }
    this.filterAreaWidth = this.area.screen;
  }

  private setAssociateScreeen() {
    return;
    if (this.activePageInfo.screenName === "HELP") return;
    if (
      this.lastScreenCode !== "" &&
      this.lastScreenCode === this.activePageInfo.screenName
    ) {
      return;
    }
    this.lastScreenCode = this.activePageInfo.screenName;
    if (this.activePageInfo.screenName === "") {
      this.associatedScreens[0].items = [];

      return;
    }
    this.screenService
      .getAssociateScreenList(this.activePageInfo.screenName)
      .subscribe((output: OutData<ScreenRecordData[]>) => {
        output.validateResponse(
          () => {
            const screenItems = [];
            output.result.forEach((element) => {
              screenItems.push({
                label: element.screenName,
                title: element.screenCode + " - " + element.screenName,
                value: element.screenCode,
                styleClass: "associatedMenuItem",
                icon: "nv-angle-double-right"
              });
            });
            this.associatedScreens[0].items = screenItems;
          },
          () => {
            this.associatedScreens[0].items = [];
          }
        );
      });
  }

  addNavigationDetails() {
    if (!this.activePageInfo) {
      this.navigationServicepending = true;

      return;
    }
    this.navigationServicepending = false;

    const formGroup: FormGroup =
      this.screenPageComponent.filterFormGroup ||
      this.screenPageComponent.rootFormGroup;
    let stickyData: any = this.messageService.stickyData;
    if (formGroup && formGroup.formSection === FormGroupSection.filterSection) {
      stickyData = formGroup.getRawValue();
    }

    // Ticket 18946
    // Sticky Not Updatd So Filter Value Assigned to searchKey
    // this.stickyService.addNavigationDetails(
    //   this.activePageInfo,
    //   stickyData,
    //   this.filterInfo,
    //   this.activeUrl
    // );
  }
  iconMenus = [
    "nv-caseInitiation",
    "nv-caseManagement",
    "nv-financial2",
    "nv-portal",
    "nv-enforcement",
    " nv-establishment2",
    " nv-tools2",
    "nv-participant_programHistory",
    "nv-receipt_process",
    "nv-source_incomeMerge",
    "nv-worker",
    "nv-financial_eventLog"
  ];
  labelMenu = [];

  private getScreenPageComponent(child) {
    if (child.screenInfo && child.screenInfo.label) {
      this.pageInfo.push(child.screenInfo);
    }
    if (child.pageList) {
      const associatedMenuItems = child.pageList.filter((menu) => {
        if (
          menu.styleClass === "associateMenuItem" ||
          menu.styleClass === "associatedMenuItem"
        ) {
          menu.styleClass = "associatedMenuItem";
          menu["icon"] = "nv-angle-double-right";

          return menu;
        }
      });
    }
    if (child.activeChild) {
      return this.getScreenPageComponent(child.activeChild);
    } else {
      return child;
    }
  }

  onUpdateClick(event) {
    if (this.screenPageComponent && this.screenPageComponent.update) {
      this.checkKeyIdentifier("update");
    }
  }
  onClearClick(event) {
    if (this.screenPageComponent && this.screenPageComponent.clear) {
      this.screenPageComponent.clear()
    }
  }
  onAddClick(event) {
    if (this.screenPageComponent && this.screenPageComponent.add) {
      this.checkKeyIdentifier("add");

    }
  }
  onDeleteClick(event) {
    if (this.screenPageComponent && this.screenPageComponent.delete) {
      this.checkKeyIdentifier("delete");
    }
  }
  onNewClick(event) {
    if (this.screenPageComponent && this.screenPageComponent.addNew) {
      this.screenPageComponent.addNew()
    }
  }

  private setScreenInfo(isHideFilter?: boolean) {
    const screenFnInfo = JSON.parse(this.storageService.getItem("SCRN_FN_INFO"));
    if (!isHideFilter) {
      this.toggleOverlay(false);
    }
    this.filterCount = 0;
    this.pageInfo = [];
    this.isUpdateScreen = false;
    this.isAddScreen = false;
    this.isDeleteScreen = false;
    this.screenPageComponent = this.getScreenPageComponent(this.activeChild);

    if (this.screenPageComponent.screenInfo) {
      if (this.screenPageComponent.screenInfo.screenType === ScreenType.edit || this.screenPageComponent.update) {
        this.isUpdateScreen = true;
      }
      if (this.screenPageComponent.screenInfo.screenType === ScreenType.add || this.screenPageComponent.add) {
        this.isAddScreen = true;
      }
      if (this.screenPageComponent.screenInfo.screenType === ScreenType.delete || this.screenPageComponent.delete) {
        this.isDeleteScreen = true;
      }
    }
    this.screenPageComponent.updateToolbar = (buttonInfo) => {
      for (const key in buttonInfo) {
        if (buttonInfo.hasOwnProperty(key)) {
          let isEnable = buttonInfo[key];
          switch (key) {
            case "add":
              if (isEnable) {
                this.isAddScreen = true;
              }
              else {
                this.isAddScreen = false;
              }
              break;
            case "update":
              if (isEnable) {
                this.isUpdateScreen = true;
              }
              else {
                this.isUpdateScreen = false;
              }
              break;
            case "delete":
              if (isEnable) {
                this.isDeleteScreen = true;
              }
              else {
                this.isDeleteScreen = false;
              }
              break;

            default:
              break;
          }


        }
      }
    }
    if (this.screenPageComponent.defaultButtonInfo) {
      this.screenPageComponent.updateToolbar(this.screenPageComponent.defaultButtonInfo);
    }

    this.messageService.activeScreen = this.screenPageComponent;
    if (this.screenPageComponent.reloadFilterTemplate) {
      this.reloadFilterTemplate = true;
    }
    if (this.activePageInfo) {
      this.prevPageInfo = Object.assign({}, this.activePageInfo);
    }
    this.activePageInfo = this.screenPageComponent.screenInfo;
    this.storageService.setItem(
      "pageInfo",
      JSON.stringify(this.screenPageComponent.screenInfo)
    );
    if (screenFnInfo) {
      this.breadcrumbItems = screenFnInfo[this.activePageInfo.screenFunction];
      this.activeScreenHeader = this.breadcrumbItems[this.breadcrumbItems.length - 1].label;
    }
    setTimeout(() => {
      if (this.screenPageComponent && this.screenPageComponent.filterTemplate) {
        this.filterTemplate = this.screenPageComponent.filterTemplate;
        this.isShowFilterBtn = true;

        if (this.navigationServicepending) {
          this.addNavigationDetails();
        }
      } else {
        this.filterTemplate = undefined;
        this.isShowFilterBtn = true;
      }
      if (this.prevPageInfo) {
        this.updateSticky(this.prevPageInfo);
      }

    }, 0);
    //  this.leftmenu.updateMenu(location.pathname, this.pageList);
    window.document.title = appConfig.applicationName;//+ " - "+ this.activePageInfo.screenName;
  }
  onInitialize() {
    this.messageService.ShowErrorDetails([]);
    this.navigationEventSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.messageService.isLastTransactionSuccess = false;
        this.activeUrl = event.urlAfterRedirects;
        setTimeout(() => {
          this.setScreenInfo();
          this.setAssociateScreeen();
        }, 100);
      }
      if (event instanceof NavigationStart) {
        this.messageService.routeRequested = event.url;
        this.messageService.ShowErrorDetails([]);
      }
    });

    if (!this.messageService["sizeChanged"]) {
      this.messageService["sizeChanged"] = () => {
        if (this.filterHeight != 0 && this.selectFilterbtn) {
        }
      };
    }
  }

  @HostListener("document:keydown", ["$event"]) onKeyDown(event) {
    if (event.which === this.f1KeyCode) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.showHelp();

      return;
    }
    if (event.altKey) {
      // M
      if (event.which === 77) {
        this.onGutterClick(null);

        return;
      }
      // W
      if (event.keyCode === 87 && this.selectFilterbtn) {
        event.preventDefault();
        event.stopImmediatePropagation();
        this.selectFilterbtn.nativeElement.click();

        return;
      }
    }
  }

  onViewReady() {
    this.setScreenInfo();
    // this.menuSplitAreaScrollFn = this.renderer.listen(
    //   this.menuSplitArea.nativeElement,
    //   "scroll",
    //   (event) => {
    //     this.pageSplitArea.nativeElement.click();
    //   }
    // );
    // this.pageSplitAreaScrollFn = this.renderer.listen(
    //   this.pageSplitArea.nativeElement,
    //   "scroll",
    //   (event) => {
    //     this.pageSplitArea.nativeElement.click();
    //   }
    //);
    this.setAssociateScreeen();
  }

  onFormValueChange() { }
  onPageSplitterTrantitionEnd(event) {
    this.filterAreaWidth = event.sizes[1];
  }

  menuLoaded() {
    const screenFnInfo = JSON.parse(this.storageService.getItem("SCRN_FN_INFO"));
    if (screenFnInfo && this.activePageInfo) {
      this.breadcrumbItems = screenFnInfo[this.activePageInfo.screenFunction];
      this.activeScreenHeader = this.breadcrumbItems[this.breadcrumbItems.length - 1].label;
    }
  }
  viewChanged() {
    this.onViewChange.emit();
  }

  showHelp() {
    const helpInData = {
      screenCode: "",
      screenFunctionCode: "",
      screenSectionCode: "",
      controlName: ""
    };
    const screenInfo = JSON.parse(this.storageService.getItem("pageInfo"));
    helpInData.screenCode = screenInfo.screenName;
    helpInData.screenFunctionCode = screenInfo.screenFunction;
    this.messageService.toggleHelpInfo(helpInData);
  }

  checkKeyIdentifier(type) {
    let filterFormGroup = null;
    if (this.screenPageComponent
      && this.screenPageComponent.rootFormGroup
      && this.screenPageComponent.rootFormGroup.formSection === FormGroupSection.filterSection) {
      filterFormGroup = this.screenPageComponent.rootFormGroup;
    }
    if (this.screenPageComponent
      && this.screenPageComponent.filterFormGroup) {
      filterFormGroup = this.screenPageComponent.filterFormGroup;
    }
    if (filterFormGroup) {
      this.messageService
        .isKeyIdentiferSet(filterFormGroup)
        .then(() => {
          this.invokeCallback(type);
        }).catch(() => {

        })
    }
    else {
      this.invokeCallback(type);
    }
  }
  invokeCallback(type) {
    switch (type) {
      case "add":
        this.screenPageComponent.add();
        break;
      case "update":
        this.screenPageComponent.update();
        break;
      case "delete":
        this.screenPageComponent.delete();
        break;
    }
  }

  onDestroy() {
    this.navigationEventSubscription.unsubscribe();
    for (
      let subscriptionsIndex = 0, length = this.subscriptions.length;
      subscriptionsIndex < length;
      subscriptionsIndex++
    ) {
      this.subscriptions[subscriptionsIndex].unsubscribe();
    }
    if (this.pageSplitAreaScrollFn) {
      this.pageSplitAreaScrollFn();
    }

    if (this.menuSplitAreaScrollFn) {
      this.menuSplitAreaScrollFn();
    }
  }



}
