import { Pipe, PipeTransform } from "@angular/core";
import { MyNjHelpsConstants } from "@mynjhelps/common";

@Pipe({ name: "optionpipe" })
export class OptionPipe implements PipeTransform {
  transform(value: any, options: any[]): string {
    let isMultiple: boolean = false;
    let valueList = [];
    if (value == null || value == "") {
      return value;
    }
    else {
      valueList = value.toString().split(",");
      // console.log(valueList)
      if (valueList.length > 1) {
        isMultiple = true;
      }
    }



    let data = [];
    if (options && options.length > 0) {


      if (isMultiple) {        
        // for multiple values : checkbox , multiselect
        options.forEach(item => {

          if (this.isAvailable(valueList, item.value)) {
            data.push(item.label);
          }

        });

        if (data.length > 0) {
          return data.join();
        }
      }
      else {
        // for single item 
        data = options.filter((item) => {
          if (item.value == value) {
            return item;
          }
        })

        if (data.length > 0) {
          return data[0].label;
        }

      }
    }

    return value;
  }

  isAvailable(data, value) {
    let returnValue = false;
    data.forEach(element => {
      if (value == element) {
        returnValue = true
      }
    });
    return returnValue;
  }
}

