<div [formGroup]="rootFormGroup">
  <lib-auto-complete class="menuSearch" *ngIf="!isMenuminimised" #screensAutoCompElement
    (onSelect)="screenChanged($event)" [options]="screenData" placeholder="Search" formControlName="screenCode"
    [optionTemplate]="optionTemplate" [minLength]="1" styleClass="hiddenLabel" (onSerach)="onScreenSearch($event)"
    [isServerSearh]="true" suggestionField="desc" valueField="CDSFN" displayField="IDSCN"></lib-auto-complete>
  <span class="nv-search searchIcon"></span>
</div>
<ng-template #optionTemplate let-suggestion>
  <div [ngClass]="{'disableOption':suggestion.split('~')[2]=='N'}">
    <span class="quickMenuOption" [innerHtml]='screensAutoCompElement.getOption(suggestion.split("~")[1])'></span>
    <br />
    <span class="quickMenuOption" [innerHtml]='screensAutoCompElement.getOption(suggestion.split("~")[0])'></span>   
  </div>
</ng-template>