import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild
} from "@angular/core";
import {
  EventArgs,
  FormGroupSection,
  OutData,
} from "@mynjhelps/common";
import { StatusCode } from "@mynjhelps/http";
import { StorageService } from "@mynjhelps/storage";
import { IScreenInfo, ScreenFormComponent } from "@mynjhelps/uibase";
import { UtilityService } from "@mynjhelps/utility";
import { AdvancedNavigationInData } from "@shared/model/screen/advanced-navigation-in-data.model";
import { AdvancedNavigationRecordData } from "@shared/model/screen/advanced-navigation-record-data.model";
import { ScreenService } from "@shared/services/screen.service";
import { StickyService } from "@shared/services/sticky.service";
import screenFnCodeUrl from "../../../../../assets/data/screen-function-code-url.json";
import { ScreenModel } from "./screen.model";

@Component({
  selector: "app-quick-navigator",
  templateUrl: "./quick-navigator.component.html",
  styleUrls: ["./quick-navigator.component.scss"]
})
export class QuickNavigatorComponent extends ScreenFormComponent {
  @ViewChild("screensAutoCompElement") screensAutoCompElement;


  private sKeyCode = 83;
  private nKeyCode = 78;
  private screenSource :any[]= null;

  screenInfo: IScreenInfo = {
    screenName: "",
    routerLink: null,
    label: "QUICKNAVIGATOR"
  };
  activePageInfo: IScreenInfo;


  screenSourceValue = [];
  @Input() isMenuminimised = false;
  @Output() onNavigationOccured = new EventEmitter<any>();
  screenData: ScreenModel[] = [];
  constructor(
    public storageService: StorageService,
    private screenService: ScreenService,
    private stickyService: StickyService
  ) {
    super();
    this.rootFormGroup = this.buildFormGroup(
      {
        screenCode: "",
      },
      FormGroupSection.bodySection
    );
  }

  compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const POSITIONA = a.POSITION;
    const POSITIONB = b.POSITION;
  
    let comparison = 0;
    if (POSITIONA > POSITIONB) {
      comparison = 1;
    } else if (POSITIONA < POSITIONB) {
      comparison = -1;
    }
    return comparison;
  }

  onScreenSearch(event: EventArgs<{ searchText: string }>) {

    if (!this.screenSource) {

      this.screenSource = JSON.parse(this.storageService.getItem("SCRN_FN_DATA"));
      this.screenSource.sort(this.compare);
    }
    const filteredData = [];
    const suggstData = [];
    this.screenSource.filter((item) => {
      if (
        item.IDSCN
          .toUpperCase()
          .indexOf(event.eventData.searchText.toUpperCase()) !== -1
      ) {
        suggstData.push(item.NMSFN + "~" + item.IDSCN + "~" + item.INDACCS)
        item["desc"] = item.NMSFN + "~" + item.IDSCN + "~" + item.INDACCS;
        filteredData.push(item);
      }
    });
    this.screenSource.filter((item) => {
      if (
        item.NMSFN
          .toUpperCase()
          .indexOf(event.eventData.searchText.toUpperCase()) !== -1
      ) {
        if (suggstData.indexOf(item.NMSFN + "~" + item.IDSCN + "~" + item.INDACCS) === -1) {
          item["desc"] = item.NMSFN + "~" + item.IDSCN + "~" + item.INDACCS;
          filteredData.push(item);
        }
      }
    });
    this.screenData = filteredData;
  }


  screenChanged() {

    let url = screenFnCodeUrl[this.rootFormGroup.value.screenCode];
    if (url) {
      if (location.pathname.indexOf(url) > -1) {
        const msg: string = this.doGetErrorMessage("W0002");
        this.doShowMessage(msg, StatusCode.Failure);

        return;
      }
      this.doNavigate(url);
      this.onNavigationOccured.emit(this.rootFormGroup.value);
    }
    this.setFormValue({ screenCode: "" });
  }

  screenSelected() { }





  resetFields() {

  }

  validateScreen(screenCode: string, queryString: string) {

  }

  onInitialize(): void { }
  onViewReady(): void { }
  onDestroy(): void { }
  onFormValueChange(data: any): void { }

}
