<ng-container *ngIf="value != '' || showOnEmpty">
  <div class="caseContainer">
    <label class="caseLabelStyle"
      >{{ labelText }}
      <span
        *ngIf="helptext"
        class="fa fa-question-circle tooltipIconStyle"
        [libtooltip]="helptext"
      ></span
    ></label>

    <div class="caseValueStyle {{ valueStyle }}">
      <ng-container *ngIf="!type && !refm && !options">
        {{ value | defaultValue }}
      </ng-container>

      <ng-container *ngIf="type == 2 && value != ''">
        <!-- currency  -->
        {{ value | defaultValue | currency: "USD":"symbol":"1.2-2" }}
      </ng-container>

      <ng-container *ngIf="type == 6">
        <!-- date -->
        {{ value | dateAlone | date: "MM/dd/yyyy" }}
      </ng-container>

      <ng-container *ngIf="type == 3">
        <!-- dateTime = 3, -->
        {{ value | validDateTime | date: "MM/dd/yyyy hh:mm a" }}
      </ng-container>

      <ng-container *ngIf="type == 5">
        <!-- refm -->

        {{ value | defaultValue | refmpipe: refm }}
      </ng-container>

      <ng-container *ngIf="type == 9">
        <!-- phone -->

        {{ value | defaultValue | phone }}
      </ng-container>

      <ng-container *ngIf="type == 12">
        <!-- ssn -->
        <ng-container *ngIf="isAdmin">
          <ng-container *ngIf="formatSsn">
            {{ value | defaultValue | ssn }}
          </ng-container>
          <ng-container *ngIf="!formatSsn">
            {{ value | defaultValue }}
            <!-- for admin pdf- no need to format ssn -->
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!isAdmin">
          {{ value | defaultValue | ssn }}
        </ng-container>
      </ng-container>

      <ng-container *ngIf="type == 13">
        <!-- zip -->
        {{ value | defaultValue | zip }}
      </ng-container>

      <ng-container *ngIf="refm">
        <!-- refm -->
        <span class="notranslate">
          <ng-container *ngIf="!multiple">
            {{ value | refmLangPipe: refm:getLanguage() }}</ng-container
          >
          <ng-container *ngIf="multiple">
            {{ value | optionpipe: getRefmOptions() }}</ng-container
          >
        </span>
      </ng-container>

      <ng-container *ngIf="options">
        <!-- option -->
        {{ value | optionpipe: options }}
      </ng-container>
    </div>
  </div>
</ng-container>
