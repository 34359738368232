import { Injectable } from "@angular/core";
import { OutData } from "@mynjhelps/common";
import { ApiService, ServiceBaseComponent } from "@mynjhelps/http";
import { Observable, Subject } from "rxjs";

import { UserIdData } from "../dashboard-model/user-id-data.model";
import { StorageService } from "@mynjhelps/storage";
import { UploadDocumentData } from "../dashboard-model/upload-document-data.model";
import { ScreenApplicationData } from "../dashboard-model/screen-application-data.model";
import { DocumentInData } from "@shared/model/common/document-in-data.model";
import { ApplicationIdInData } from "@shared/model/common/application-id-in-data.model";
import { UtilityService } from "@mynjhelps/utility";
import { DocumentAttachmentData } from "../dashboard-components/upload/upload-model/document-attachment-data.model";
import { NoticeData } from "../dashboard-model/notice-data.model";
import { ApplicationUserKeyData } from "lib-mynjh-shared";
import { CaseLinkVerificationInData } from "../dashboard-model/case-link-verification-in-data.model";
import { CaseSpecificsInData } from "../dashboard-model/case-specific-in-data.model";
import { NoticePdfGenerationInData } from "../dashboard-model/notice-pdf-generation-in-data.model";
import { RefmInData } from "../dashboard-components/case/case-model/refm-in-data.model";
import { RequestDetailsInData } from "../dashboard-model/request-details-in-data.model";
import { RequestEBTInData } from "../dashboard-model/request-ebt-in-data.model";
import { CaseKeyData } from "../dashboard-model/case-key-data.model";
//import { ApplicationUserKeyData } from "@shared/model/common/application-user-key-data.model";

@Injectable({
  providedIn: "root"
})
export class DashboardService extends ServiceBaseComponent {
  navigation: Subject<any> = new Subject();

  applicationId: string = "";
  uploadApplicationId: string = "";
  uploadMemberId: number = 0;
  selectedCaseData = {
    CaseID: "",
    StatusCode: "",
    EnableIndc: ""
  };
  screenData: UploadDocumentData = new UploadDocumentData();

  constructor(apiService: ApiService, private storageService: StorageService) {
    super(apiService);
  }

  translate: Subject<any> = new Subject();

  changeLanguage(lang) {
    this.translate.next(lang);
  }

  setUploadApplicationId(val) {
    this.uploadApplicationId = val;
  }

  getApplicationId() {
    this.applicationId = this.storageService.getItem("applicationId");
    return this.applicationId;
  }

  getApplicationList(inputData: UserIdData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Applicant/GetApplicationList");
  }

  CreateNewApplication(inputData: ScreenApplicationData): Observable<any> {
    return this.apiService.Post(inputData, "Account/CreateNewApplication");
  }

  resetScreenData() {
    this.storageService.removeItem("applicationId");
    this.storageService.removeItem("uploadMemberId");
    this.uploadApplicationId = "";
    this.uploadMemberId = 0;
    this.screenData = new UploadDocumentData();
  }

  CreateUploadUserAccount(input: UploadDocumentData): Observable<OutData<any>> {
    return this.apiService.Post({}, "Document/CreateUploadUserAccount");
  }

  SubmitApplication(
    inputData: ScreenApplicationData
  ): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Applicant/SubmitApplication");
  }
  SubmitUploadApplication(
    inputData: ScreenApplicationData
  ): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Document/SubmitUploadApplication");
  }
  GetAttachment(inputData: DocumentInData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Document/GetAttachment");
  }

  getMembersList(inputData: ApplicationIdInData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Applicant/GetMemberList");
  }

  getMemberIdList(): void {
    const basicInData = new ApplicationIdInData();
    basicInData.ApplicationID = this.uploadApplicationId;
    this.getMembersList(basicInData).subscribe((result: any) => {
      result.validateResponse(
        () => {
          if (result.StatusList[0].Code === "S") {
            this.uploadMemberId = result.RecordsList[0].MemberIdno;
            // const memberList = UtilityService.convertDropdownData(
            //   result.RecordsList,
            //   "FullName",
            //   "MemberIdno"
            // );
            // for (const memberid of memberList) {
            //   memberid.label = memberid.label.toString();
            // }
            //
            // console.log(memberList)

            //this.participantList = memberList;
          }
        },
        () => {}
      );
    });
  }

  setNavigation() {
    this.navigation.next("NAV");
  }

  updateLeftMenu() {
    this.navigation.next("LEFT_MENU");
  }

  RemoveUploadedDocument(
    inputData: DocumentAttachmentData
  ): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Document/RemoveUploadedDocument");
  }
  GetNoticeDetailsList(inputData: NoticeData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Applicant/GetNoticeDetailsList");
  }
  CheckApplicationExists(inputData: UserIdData): Observable<any> {
    return this.apiService.Post(inputData, "Account/CheckApplicationExists");
  }
  GetUserLanguage(inputData: ApplicationUserKeyData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Applicant/GetUserLanguage");
  }

  GetCaseStatus(inputData: ApplicationUserKeyData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Applicant/GetCaseStatus");
  }

  UpdateDocumentType(
    inputData: DocumentAttachmentData
  ): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Document/UpdateDocumentType");
  }

  SaveApplicationVersion(
    inputData: ApplicationUserKeyData
  ): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "Applicant/SaveApplicationVersion");
  }

  GetCaseLinkVerification(
    inputData: CaseLinkVerificationInData
  ): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "CaseSummary/GetCaseVerification");
  }

  GetCaseDetailList(inputData: UserIdData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "CaseSummary/GetCaseDetailList");
  }

  GetCaseNoticeDetails(inputData: NoticeData): Observable<any> {
    return this.apiService.Post(inputData, "CaseSummary/GetCaseNoticeDetails");
  }

  GetDefaultDateRange(inputData: ApplicationUserKeyData): Observable<any> {
    // return this.apiService.Post({}, "Anonymous/GetDefaultDateRange");
    // return this.apiService.Post({}, "Anonymous/GetDefaultDateRange", "", {}, true);
    return this.apiService.Post(inputData, "CaseSummary/GetDefaultDateRange");
    // return this.apiService.Post({}, "Anonymous/GetSystemDate", "", {}, true);
  }

  GetNoticePdf(inputData: ApplicationUserKeyData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "CaseSummary/GetNoticePdf");
  }

  GetNoticePdfGeneration(inputData: NoticePdfGenerationInData) {
    // return this.apiService.Post(inputData, "CaseSummary/GetNoticePdfGeneration");
    return this.apiService.PostFileDownload(
      inputData,
      "CaseSummary/GetNoticePdfGeneration",
      "fileName",
      "pdf",
      "",
      "APPL",
      true
    );
  }

  GetReferenceValues(inputData: RefmInData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "CaseSummary/GetReferenceValues");
  }


  GetRequestDetails(inputData: RequestDetailsInData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "CaseSummary/GetRequestDetails");
  }
 
 
  RequestEbtCardReplacement(inputData: RequestEBTInData): Observable<OutData<any>> {
    return this.apiService.Post(
      inputData,
      "CaseSummary/RequestEbtCardReplacement"
    );
  }

  GetEbtCardReplacementStatus(inputData: RequestDetailsInData): Observable<OutData<any>> {
    return this.apiService.Post(
      inputData,
      "CaseSummary/GetEbtCardReplacementStatus"
    );
  }

  CheckEbtCardRequestExists(inputData: CaseKeyData): Observable<OutData<any>> {
    return this.apiService.Post(inputData, "CaseSummary/CheckEbtCardRequestExists");
  }


}
