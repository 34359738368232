import { Pipe, PipeTransform } from "@angular/core";
import { StorageService } from "@mynjhelps/storage";

@Pipe({ name: "refmpipe" })
export class RefmPipe implements PipeTransform {
  constructor(private storageService: StorageService) { }
  /**
   * angular interface for Pipe
   * @param refmValue
   * @param type
   */
  // transform(refmValue: string, type: string): string {
  //   let refmData = JSON.parse(this.storageService.getItem("REFM"));
  //   if (
  //     type &&
  //     type !== undefined &&
  //     refmData[type] !== undefined &&
  //     refmValue !== "" &&
  //     refmValue !== undefined
  //   ) {
  //     let data = refmData[type];
  //     for (let index in data) {
  //       if (data[index].value === refmValue) {
  //         return data[index].label;
  //       }
  //     }
  //   } else {
  //     return refmValue;
  //   }
  // }

  transform(refmValue: string, type: string): string {
    let refmData = JSON.parse(this.storageService.getItem("STORE"));
    if (
      type &&
      type !== undefined &&
      refmData.REFM[type].length !== 0 &&
      refmValue !== "" &&
      refmValue !== undefined
    ) {
      let data = refmData.REFM[type];
      
      for (let index in data) {
        if (data[index].value == refmValue) {
          return data[index].label;
        }
      }
    } else {
      return refmValue;
    }
  }
}


