import { BaseModel } from "@mynjhelps/common";

export class DataModelBase extends BaseModel {

    public ClientIPText: string = "";

    public BrowserDetailsText: string = "";

    public BrowserVersionText: string = "";

    public ServiceMethodName: string = "";

    public ServiceName: string = "";

    public ServiceScreenFunctionID: string = "";

    public ServiceScreenID: string = "";

    public SignedOnOfficeIdno: number = 0;

    public TransactionEventSeqNumb: number = 0;

}