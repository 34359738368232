import { BaseModel, MyNjHelpsConstants } from '@mynjhelps/common';

export class StickyDataSaveIn extends BaseModel{
    CD_ACTIVITY="";
    ID_SESSION:string="";
    ID_SCREEN:string="";
    ID_WORKER_SIGNEDON:string="";
    CD_OFFICE_SIGNEDON:string="";
    ID_WORKER:string="";
    TS_CREATE:string="";
    ID_CASE:string="";
    ID_CASE_WELFARE:string="";
    ID_MEMBER:string="";
    CD_RELATION_CASE:string="";
    NAME_LAST:string="";
    NAME_FIRST:string="";
    NAME_MI:string="";
    ID_MEMBER_WELFARE:string="";
    MEM_SSN:string="";
    NCP_ID_MEMBER:string="";
    NCP_SSN:string="";
    CP_ID_MEMBER:string="";
    CP_SSN:string="";
    ID_ORDER:string="";
    SEQ_ORDER:number=MyNjHelpsConstants.NULL_INT;
    ID_DOCKET:string="";
    SEQ_OBLIGATION:number=MyNjHelpsConstants.NULL_INT;
    DT_BEG_OBLIGATION:null;
    CD_TYPE_DEBT:string="";
    DT_BATCH:string="";
    CD_SOURCE_BATCH:string="";
    NO_BATCH:number=MyNjHelpsConstants.NULL_INT;
    SEQ_RECEIPT:string="";
    CD_SOURCE_RECEIPT:string="";
    DT_RECEIPT:string="";
    ID_PAYOR:string="";
    CD_TYPE_REMITTANCE:string="";
    ID_CHECK_RECIPIENT:string="";
    CD_CHECK_RECIPIENT:string="";
    DT_DISBURSE:string="";
    MTH_WELFARE:number=MyNjHelpsConstants.NULL_INT;
    NO_CHECK:string="";
    SEQ_MAJOR_INT:number=MyNjHelpsConstants.NULL_INT;
    SEQ_MINOR_INT:number=MyNjHelpsConstants.NULL_INT;
    CD_ACTIVITY_MAJOR:string="";
    CD_ACTIVITY_MINOR:string="";
    CD_STATE_FIPS:string="";
    CD_TYPE_ADDRESS:string="";
    ID_OTHER_PARTY:string="";
    ID_FIPS:string="";
    ID_NOTICE:string="";
    DT_FROM:string="";
    DT_TO:string="";
    ID_TRANS_HEADER:string="";
    CD_OTH_STATE_FIPS:string="";
    DT_TRANSACTION:string="";
    CD_COUNTY:string="";
    CD_OFFICE:string="";
    CD_CASH_LOCATION:string="";
    ID_JOB:string="";
    PACKAGE_NAME:string="";
    IND_HIS_NAVIGATION:string="";
    HISTORY_POSITION:number=MyNjHelpsConstants.NULL_INT;
    ID_TICKET:string="";
    CD_STATUS:string="";
    CD_SEVERITY:string="";
    ID_CASE_OTH_STATE:string="";
    SEQ_GUIDELINE:string="";
    ID_PLAINTIFF:string="";}